import React, { FC } from 'react';
import { M24MediaModel } from '@/declarations/models/M24MediaModel';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useController } from 'react-hook-form';

export const SetAsMainImageCheckbox: FC<{ image?: M24MediaModel }> = ({ image }) => {
  const { t } = useTranslation('components');
  const {
    field: { value: pageMainImageValue, onChange: onChangePageMainImage },
  } = useController({
    name: 'image',
  });

  if (!image) return null;
  return (
    <FormControlLabel
      sx={{
        alignSelf: 'stretch',
        width: '100%',
      }}
      control={
        <Checkbox
          checked={image.id === pageMainImageValue?.id}
          onChange={(e) => {
            if (e.target.checked) {
              onChangePageMainImage(image);
            } else {
              onChangePageMainImage(undefined);
            }
          }}
        />
      }
      label={<Typography variant='caption'>{t('common.setAsPageMainImage')}</Typography>}
    />
  );
};
