import React, { FC } from 'react';
import { Checkbox, Chip, IconButton, ListItemAvatar, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '../../../assets/js/Styles';
import { EditState } from '../MediaView';
import { getImage, getUsageText, MediaViewItemProps } from './GridViewItem';
import Container from '../../../components/Container';
import { formatAPITimestamp } from '../../../utils/dates';

export const noWrapTextStyle = {
  maxWidth: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};
const smallScrollBarStyle = {
  maxWidth: '100%',
  padding: '4px 0',
  overflowX: 'auto',
  '::-webkit-scrollbar': { height: '8px' },
  '::-webkit-scrollbar-track': { backgroundColor: 'transparent' },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
  },
  '::-webkit-scrollbar-thumb:hover': { backgroundColor: '#555' },
};

export const buttonHoverStyle = (theme: Theme) => ({
  backgroundColor: Styles.Colors.THEME_PRIMARY_BG_HOVER,
  color: theme.palette.getContrastText(Styles.Colors.THEME_PRIMARY_BG_HOVER),
  cursor: 'pointer',
});

export const ListViewItem: FC<MediaViewItemProps> = ({ item, onItemClick, isSelected, editState, onDeleteItem }) => {
  const { t } = useTranslation('components');

  const enContent = item.content?.en;
  const isEn = i18next.language === 'en';

  const itemWidth = `calc(100% - ${editState === EditState.SINGLE ? '40px' : '80px'})`;

  return (
    <Container
      fullWidth
      spaceBetween
      gap={0}
      sx={{
        outline: isSelected ? `4px solid ${Styles.Colors.MEDIA_ITEM_SELECTED_COLOR}` : undefined,
        margin: '5px 0',
        borderRadius: Styles.Dimensions.RADIUS_ROUND_SM,
      }}>
      {editState !== EditState.SINGLE && (
        <Checkbox sx={{ padding: '8px' }} color='success' checked={isSelected} onClick={() => onItemClick(item)} />
      )}
      <Container
        left
        onClick={() => onItemClick(item)}
        sx={(theme) => ({
          width: itemWidth,
          maxWidth: itemWidth,
          '&:hover': buttonHoverStyle(theme),
        })}>
        <ListItemAvatar sx={{ height: '64px' }}>{getImage(item, 64, 64)}</ListItemAvatar>
        <Container fullWidth sx={{ maxWidth: 'calc(100% - 68px)' }} spaceBetween>
          <Container fullWidth column gap={0} left sx={{ maxWidth: 'calc(100% - 250px)' }}>
            <Typography fontWeight={700} sx={noWrapTextStyle}>
              {isEn && enContent?.title ? enContent.title : item.title || item.content?.title || item.filename}
            </Typography>
            {editState === EditState.GROUP_UPDATE && !!item.tags?.length && (
              <Container fullWidth left gap={0.5} sx={smallScrollBarStyle}>
                {item.tags.map((tag) => (
                  <Chip key={tag.tag} color='success' label={tag.tag} variant='outlined' size='small' />
                ))}
              </Container>
            )}
            {editState === EditState.SINGLE &&
              (!(isEn ? enContent?.alttext : item?.content?.alttext) ? (
                <Container sx={{ maxWidth: '100%' }}>
                  <MaterialSymbol name='warning' color='warning' />
                  <Typography color={Styles.Colors.STRONG_ORANGE} sx={noWrapTextStyle} lineHeight={1} mt='4px'>
                    {t('MediaView.MissingAlt')}
                  </Typography>
                </Container>
              ) : (
                <Typography variant='caption' sx={noWrapTextStyle}>
                  <b>{t('MediaEditor.AltText')}: </b>
                  {isEn ? enContent?.alttext : item?.content?.alttext}
                </Typography>
              ))}
          </Container>
          <Container column gap={0} left sx={{ width: 'max(250px, 35%)', maxWidth: 'max(250px, 35%)' }}>
            {editState === EditState.GROUP_UPDATE && !!item.content?.license_code && (
              <Typography variant='caption' sx={noWrapTextStyle}>
                <b>{t('MediaView.License')}: </b>
                {item.content.license_code.toUpperCase()}
              </Typography>
            )}
            {editState === EditState.GROUP_UPDATE &&
              item.content?.credits?.slice(0, 2).map(
                (credit, i) =>
                  !!credit.type && ( // eslint-disable-next-line react/no-array-index-key
                    <Typography key={`${i}-${credit.type}`} variant='caption' sx={noWrapTextStyle}>
                      <b>{t(`MediaView.CreditTypes.${credit.type}`)}: </b>
                      {credit.name}
                    </Typography>
                  ),
              )}
            {editState === EditState.SINGLE && (
              <>
                <Typography variant='caption' sx={noWrapTextStyle}>
                  <b>{t(`MediaView.Created_at`)}: </b>
                  {formatAPITimestamp(item.created_at, 'datetime')}
                </Typography>
                {item.updated_at && (
                  <Typography variant='caption' sx={noWrapTextStyle}>
                    <b>{t(`MediaView.Updated_at`)}: </b>
                    {formatAPITimestamp(item.updated_at, 'datetime')}
                  </Typography>
                )}
              </>
            )}
            {editState === EditState.GROUP_DELETE && getUsageText(t, item.media_usages)}
          </Container>
        </Container>
      </Container>
      <IconButton onClick={() => onDeleteItem && onDeleteItem(item)}>
        <MaterialSymbol name='delete' fill />
      </IconButton>
    </Container>
  );
};

export default ListViewItem;
