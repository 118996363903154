import React, { FC, useEffect, useMemo } from 'react';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { includePage } from '@/utils/functions';
import M24PageFinderPageTreeContent from './M24PageFinderPageTreeContent';
import { useStore } from '../../../store/Store';
import { useM24PageFinderContentView } from '../M24PageFinder';
import { Page } from '../../../../declarations/models/Page';
import Container from '../../../Container';

const M24PageFinderPageTree: FC<{ isEvent?: boolean; frontpage?: Page }> = ({ isEvent, frontpage }) => {
  const { t } = useTranslation('aria');
  const { state } = useStore();
  const { expandedPageIds, setExpandedPageIds, selectionState, filterValues, hasFilterApplied, sortFn, pages } =
    useM24PageFinderContentView();
  const { getRootNodes, getChildren, hasChildren, itemCount } = selectionState;

  const defaultExpandedIds = useMemo<Array<string>>(() => {
    const roots = getRootNodes(frontpage?.id).map((n) => String(n.id || ''));
    return roots.length <= 5 ? roots : [];
  }, [frontpage?.id, getRootNodes]);

  const visibleNodeIds = useMemo<null | Array<string | number>>(() => {
    if (!hasFilterApplied) {
      return null;
    }
    const getVisibleNodeIds = (page: Page): Array<string | number> => {
      const key = page.id || 0;
      if (hasChildren(key)) {
        const visibleChildren = getChildren(key).reduce((ids, child) => {
          ids.push(...getVisibleNodeIds(child).filter((id) => !ids.includes(id)));
          return ids;
        }, [] as Array<string | number>);

        if (visibleChildren.length > 0) {
          visibleChildren.push(key);
          return visibleChildren;
        }
      }
      return includePage(filterValues, page) ? [key] : [];
    };

    return getRootNodes(frontpage?.id).reduce((ids, root) => {
      ids.push(...getVisibleNodeIds(root));
      return ids;
    }, [] as Array<number | string>);
  }, [hasFilterApplied, getRootNodes, frontpage?.id, hasChildren, filterValues, getChildren]);

  useEffect(() => {
    if (hasFilterApplied && !!visibleNodeIds?.length) {
      setExpandedPageIds(visibleNodeIds.map(String));
    } else {
      setExpandedPageIds(defaultExpandedIds);
    }
  }, [hasFilterApplied, defaultExpandedIds, setExpandedPageIds, visibleNodeIds, getRootNodes]);

  const selectedOutline = `2px solid ${Styles.Colors.STRONG_GREEN}`;

  const renderNode = (page: Page) => {
    const pageId = page.id || 0;
    const childNodes = getChildren(pageId).sort(sortFn);
    if (visibleNodeIds && !visibleNodeIds.includes(pageId)) {
      return null;
    }
    const selected = selectionState.isSelected(pageId);
    return (
      <TreeItem
        key={pageId}
        nodeId={String(pageId)}
        ContentProps={{
          style: {
            outline: selected ? selectedOutline : undefined,
            margin: '6px 0',
          },
        }}
        label={
          <M24PageFinderPageTreeContent
            page={page}
            diffuse={hasFilterApplied && !includePage(filterValues, page)}
            selected={selected}
          />
        }>
        {hasChildren(pageId) ? childNodes.map(renderNode) : null}
      </TreeItem>
    );
  };

  if (!itemCount) {
    return null;
  }

  return (
    <>
      {hasFilterApplied || isEvent ? (
        <Container fullWidth column gap={1}>
          {pages
            ?.sort(sortFn)
            .filter((p) => includePage(filterValues, p))
            .map((page) => {
              const pageId = page.id ?? 0;
              const selected = selectionState.isSelected(pageId);
              return (
                <Container
                  key={pageId}
                  fullWidth
                  sx={{
                    outline: selected ? selectedOutline : undefined,
                    px: 2,
                    backgroundColor: !isEvent ? Styles.Colors.LIGHT_GREY : Styles.Colors.STRONG_GREEN_TRANSPARENT,
                  }}>
                  <M24PageFinderPageTreeContent
                    page={page}
                    diffuse={hasFilterApplied && !includePage(filterValues, page)}
                    selected={selected}
                  />
                </Container>
              );
            })}
        </Container>
      ) : (
        <>
          {frontpage && (
            <Container
              fullWidth
              sx={{
                outline: selectionState.isSelected(frontpage.id!) ? selectedOutline : undefined,
                px: 2,
                backgroundColor: Styles.Colors.LIGHT_BLUE,
              }}>
              <M24PageFinderPageTreeContent page={frontpage} selected={selectionState.isSelected(frontpage.id!)} />
            </Container>
          )}
          <TreeView
            sx={{ width: '100%' }}
            id='page-tree'
            aria-label={t('components.PageTree.PageTreeDescription', {
              siteName: state.selectedSite?.name || 'unknown',
            })}
            expanded={expandedPageIds}
            onNodeToggle={(_, ex) => setExpandedPageIds(ex)}
            defaultCollapseIcon={<MaterialSymbol name='expand_more' />}
            defaultExpandIcon={<MaterialSymbol name='chevron_right' />}
            disableSelection>
            {getRootNodes(frontpage?.id)
              .filter((p) => !frontpage || p.id !== frontpage.id)
              .sort(sortFn)
              .map(renderNode)}
          </TreeView>
        </>
      )}
    </>
  );
};

export default M24PageFinderPageTree;
