import React, { FC, ReactNode } from 'react';
import { Divider, List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomIcon from '@/components/CustomIcon';
import Container from './Container';
import { MenuItem as MenuItemModel, MenuItemDivider } from '../declarations/models/MenuItem';
import MenuItem from './MenuItem';
import { useStore } from './store/Store';
import { MaterialSymbol } from './MaterialSymbol';

export interface MainMenuProps {
  open: boolean;
  toggleMenuOpen: () => void;
  items?: Array<MenuItemModel | MenuItemDivider>;
}

export const MainMenu: FC<MainMenuProps> = ({ open, toggleMenuOpen, items }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { state } = useStore();

  let languageMenuItems: Array<{ icon?: ReactNode; path: string; label: string }> = [];
  if (state?.selectedSite?.site_contents) {
    languageMenuItems = state?.selectedSite?.site_contents?.map((content) => {
      return { path: `/language-settings/${content.locale}`, label: tCommon(`Languages.${content.locale}`) };
    });
  }

  const MENU_ITEMS: Array<MenuItemModel | MenuItemDivider> = [
    {
      icon: <MaterialSymbol name='home' />,
      path: '/dashboard',
      label: tComponents('MainMenu.dashboard'),
      roles: 'user,editor,siteAdmin,superAdmin',
    },
    {
      icon: <MaterialSymbol name='folder_open' />,
      path: '/content',
      label: tComponents('MainMenu.content'),
      roles: 'editor,siteAdmin,superAdmin',
    },
    {
      icon: <CustomIcon name='calendar_event' />,
      path: '/events',
      label: tComponents('MainMenu.events'),
      roles: 'user,editor,siteAdmin,superAdmin',
    },
    {
      icon: <MaterialSymbol name='photo_library' />,
      path: '/media',
      label: tComponents('MainMenu.media'),
      roles: 'user,editor,siteAdmin,superAdmin',
    },
    {
      icon: <MaterialSymbol name='badge' />,
      path: '/employees',
      label: tComponents('MainMenu.employees'),
      roles: 'editor,siteAdmin,superAdmin',
    },
    {
      icon: <CustomIcon name='card' />,
      path: '/cards',
      label: tComponents('MainMenu.cards'),
      roles: 'editor,siteAdmin,superAdmin',
    },
    {
      icon: <MaterialSymbol name='assignment' />,
      path: '/forms',
      label: tComponents('MainMenu.forms'),
      roles: 'siteAdmin,superAdmin',
    },
    {
      icon: <MaterialSymbol name='settings' />,
      path: '/settings/',
      label: tComponents('MainMenu.settings.site'),
      roles: 'siteAdmin,superAdmin',
      // subItems: [
      //   { path: '/settings/site/sharing', label: tComponents('MainMenu.settings.sharing') },
      //   { path: '/settings/site/locations', label: tComponents('MainMenu.settings.locations') },
      //   { path: '/settings/site/users', label: tComponents('MainMenu.settings.users') },
      //   { path: '/settings/site/keywords', label: tComponents('MainMenu.settings.keywords') },
      //   { path: '/settings/site/categories', label: tComponents('MainMenu.settings.categories') },
      // ],
    },
    {
      icon: <MaterialSymbol name='language' />,
      path: '/language-settings',
      label: tComponents('MainMenu.settings.language.title'),
      roles: 'siteAdmin,superAdmin',
      subItems: languageMenuItems.concat({
        icon: <MaterialSymbol name='add' style={{ fontSize: '16px' }} />,
        path: 'language-settings/create',
        label: tComponents('MainMenu.settings.language.create'),
      }),
    },
  ];

  const menuItems = items || MENU_ITEMS;

  return (
    <Container gap={0} top left py={1} fullHeight fullWidth>
      <List sx={{ width: '100%' }} role='navigation'>
        {menuItems?.map((value) => {
          if (value === 'divider') {
            return <Divider key={Math.random()} sx={{ my: 2 }} />;
          }
          // Todo check if currentUser.role (for currentSite!) is inside value.roles..
          // Need to know the user's role for this site somehow
          return <MenuItem key={value.label} item={value} menuIsOpen={open} toggleMenuOpen={toggleMenuOpen} />;
        })}
      </List>
    </Container>
  );
};

export default MainMenu;
