import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import Container from '@/components/Container';
import { useStore } from '../../../components/store/Store';
import { Api } from '../../../services/Api';
import BaseSettingsViewForm from '../../BaseSettingsViewForm';
import { SiteSharingModel } from '../../../declarations/models/SiteSharingModel';
import SiteSharingSettingsForm from './SiteSharingSettingsForm';

export const SiteSharingSettings: FC = () => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;

  const handleSaveSettingsForm = useCallback(
    async (siteSharingModel: SiteSharingModel): Promise<void | SiteSharingModel> => {
      const savedFormData = await Api.saveSiteSharing<SiteSharingModel>(siteSharingModel, selectedSiteId).fetchDirect(
        null,
      );
      if (!savedFormData) {
        enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
      } else {
        enqueueSnackbar(tCommon('saved'), { variant: 'success' });
        return savedFormData;
      }
    },
    [enqueueSnackbar, selectedSiteId, tCommon],
  );

  const handleLoadModel = () => {
    return Api.getSiteSharing(selectedSiteId);
  };

  return (
    <BaseSettingsViewForm<SiteSharingModel>
      title=''
      onSubmit={handleSaveSettingsForm}
      loadModel={handleLoadModel}
      saveButtonInHeader={false}
      saveButtonInTop={false}
      withLayout={false}>
      <Container left fullWidth column p={2}>
        <Typography my={2} variant='h1' fontSize='xx-large'>
          {tComponents(`MainMenu.settings.sharing`)}
        </Typography>
        <SiteSharingSettingsForm />
      </Container>
    </BaseSettingsViewForm>
  );
};

export default SiteSharingSettings;
