/* istanbul ignore file */
import React, { StrictMode, Suspense } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { configureI18N } from './i18n/i18n-config';
import { DEFAULT_THEME } from './themes';
import './index.css';
import Loader from './components/Loader';
import { App } from './App';
import Store from './components/store/Store';
import { Settings } from './Settings';
import { ConfirmDialogProvider } from './components/ConfirmDialogProvider';

Sentry.init({
  dsn: 'https://22da4416200de79388dcd2a39cc18a7a@o1289125.ingest.us.sentry.io/4507299792879616',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

configureI18N().then();

Settings.validateSettings();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <ThemeProvider theme={DEFAULT_THEME}>
      <CssBaseline />
      <Suspense fallback={<Loader />}>
        <SnackbarProvider autoHideDuration={2000} maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Store>
            <DndProvider backend={HTML5Backend}>
              <ConfirmDialogProvider>
                <App />
              </ConfirmDialogProvider>
            </DndProvider>
          </Store>
        </SnackbarProvider>
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
);
