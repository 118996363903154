import React, { FC } from 'react';
import { ImageListItemBar } from '@mui/material';
import { CommonMediaListViewTypeProps } from '../../CommonMediaListViewTypeProps';
import Styles from '../../../../assets/js/Styles';
import FavouriteButton from '../../../FavouriteButton';
import { GenericMedia } from '../../../../declarations/GenericMedia';
import Image from '../../../Image';

export interface GridViewDefaultVariantProps extends Omit<CommonMediaListViewTypeProps, 'items'> {
  item: GenericMedia;
  isFavourite: boolean;
}

export const GridViewDefaultVariant: FC<GridViewDefaultVariantProps> = ({
  item,
  isFavourite,
  onFavouriteChanged,
  isFavouriteable,
}) => {
  let imgMimeType = '';
  if (item?.mimetype?.includes('png')) {
    imgMimeType = `mediaType=${item?.mimetype}`;
  }
  return (
    <>
      <Image
        src={`${item.url}?dimension=250x250&${imgMimeType}`}
        alt={item.title}
        loading='lazy'
        sx={{
          width: '200px',
          height: '200px',
          objectFit: 'contain',
          background: '#333',
          overflow: 'hidden',
          flex: 1,
        }}
      />
      <ImageListItemBar
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          color: Styles.Colors.WHITE,
          height: '33%',
          width: '100%',
          background: 'linear-gradient(to top, #000B, #0003 76%, transparent)',
        }}
        title={item.title}
        subtitle={item.description}
        actionIcon={
          isFavouriteable && (
            <FavouriteButton
              isFavourite={isFavourite}
              onChange={(e) => {
                e.stopPropagation();
                onFavouriteChanged?.(item, !isFavourite);
              }}
              color='inherit'
              selectedColor='inherit'
            />
          )
        }
        position='bottom'
        actionPosition='left'
      />
    </>
  );
};

export default GridViewDefaultVariant;
