/* eslint-disable no-console */
import { findDefaultPageId } from '@/utils/functions';
import { Skin } from '@/declarations/models/Skin';
import { M24State } from '../../declarations/M24State';
import { StoreAction } from './StoreAction';
import { ActionType } from '../../declarations/ActionType';
import { isActionType, isPage, isSite } from '../../utils/typeChecks';
import { StoreError } from '../../declarations/StoreError';
import { UserInformation } from '../../declarations/models/UserInformation';

const ACTION_TYPES_THAT_REQUIRES_PAYLOAD: Array<ActionType> = [ActionType.TOGGLE_FAVOURITE_PAGE];

/**
 * The main reducer: Responsible for mutating the application state
 */
export function storeReducer<T>(prevState: M24State, action: StoreAction<T> | ActionType): M24State {
  const state: M24State = { ...prevState };
  let type: ActionType | null = null;
  let payload: T | null = null;

  if (isActionType(action)) {
    type = action;
  } else if (isActionType(action?.type)) {
    type = action.type;
    payload = action.payload || null;
  } else {
    throw new StoreError('Invalid ActionType');
  }

  if (!payload && ACTION_TYPES_THAT_REQUIRES_PAYLOAD.includes(type)) {
    throw new StoreError(`Missing payload for type '${String(type)}'`);
  }

  switch (type) {
    case ActionType.CHANGE_SITE_CONTEXT: {
      state.selectedSiteData = null;
      if (!payload) {
        state.selectedSite = null;
        state.selectedSiteLanguage = null;
      } else if (isSite(payload)) {
        state.selectedSite = payload;
        state.selectedSiteLanguage = payload.default_language || null;
      } else {
        console.warn('Payload is not a valid Site');
        return prevState;
      }
      break;
    }
    case ActionType.CHANGE_SITE_CONTEXT_LANGUAGE: {
      state.selectedLanguageSiteData = null;
      if (!state.selectedSite) {
        console.warn('Cannot set current language when current site is not set');
        if (state.selectedSiteLanguage) {
          state.selectedSiteLanguage = null;
          break;
        }
        return prevState;
      }
      if (!payload) {
        if (state.selectedSiteLanguage && state.selectedSiteLanguage !== state.selectedSite.default_language) {
          state.selectedSiteLanguage = state.selectedSite.default_language;
          break;
        }
        return prevState;
      }
      const availableLocales = (state.selectedSite.site_contents || []).map((c) => c.locale);
      if (typeof payload !== 'string' || !availableLocales.includes(payload)) {
        console.warn(
          `Payload is not a valid locale. Expected one of [${availableLocales.join(', ')}], but got '${payload}'`,
        );
        return prevState;
      }
      if (payload === state.selectedSiteLanguage) {
        return prevState;
      }
      state.selectedSiteLanguage = payload;
      break;
    }
    case ActionType.SET_CURRENT_USER:
      if (payload && typeof payload === 'object' && !('user' in payload)) {
        return prevState;
      }
      state.currentUser = (payload as unknown as UserInformation) ?? null;
      break;
    case ActionType.TOGGLE_FAVOURITE_PAGE: {
      if (!payload || !isPage(payload) || !payload.id || !payload.locale || !payload.site_id) {
        console.warn('Unable to toggle favourite page. Invalid or not enough data: ', payload);
        return prevState;
      }
      if (!state.favouritePages) {
        state.favouritePages = {};
      }
      if (!state.favouritePages[payload.site_id]) {
        state.favouritePages[payload.site_id] = {};
      }
      if (!state.favouritePages[payload.site_id][payload.locale]) {
        state.favouritePages[payload.site_id][payload.locale] = [];
      }
      const existingFavourites = state.favouritePages[payload.site_id][payload.locale];
      const existingIdx = existingFavourites.indexOf(payload.id);
      if (existingIdx >= 0) {
        existingFavourites.splice(existingIdx, 1);
        if (existingFavourites.length === 0) {
          if (Object.keys(state.favouritePages[payload.site_id]).length === 1) {
            delete state.favouritePages[payload.site_id];
          } else {
            delete state.favouritePages[payload.site_id][payload.locale];
          }
        }
      } else {
        existingFavourites.push(payload.id);
      }
      return state;
    }
    case ActionType.NOOP:
      // Return the `prevState` instead of `state` to bail out of the mutation,
      // instead of actually triggering a rerender
      return prevState;
    case ActionType.SET_SITE_SKINS:
      state.selectedSiteSkins = payload as unknown as Array<Skin>;
      break;
    case ActionType.SET_SITE_THEMES:
      state.selectedSiteThemes = payload as unknown as Array<Skin>;
      break;
    case ActionType.SET_SELECTED_SITE_DATA:
      state.selectedSiteData = payload as unknown as M24State['selectedSiteData'];
      break;
    case ActionType.SET_SELECTED_LANGUAGE_DATA:
      state.selectedLanguageSiteData = payload as unknown as M24State['selectedLanguageSiteData'];
      break;
    default:
      throw new StoreError(`Missing reducer for ActionType '${type}'`);
  }

  if ([ActionType.CHANGE_SITE_CONTEXT, ActionType.CHANGE_SITE_CONTEXT_LANGUAGE].includes(type)) {
    const defaultPageId = findDefaultPageId(state?.selectedSite, state.selectedSiteLanguage);
    if (defaultPageId !== state.selectedSiteDefaultPageId) {
      state.selectedSiteDefaultPageId = defaultPageId;
    }
  }

  return state;
}
