import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { User } from '@/declarations/models/User';
import { useStore } from '@/components/store/Store';

type UserWithNoAccessValue = User & { is_noAccess: boolean };

type SiteUsersSettingsListProps = {
  users: UserWithNoAccessValue[];
};

export const SiteUsersSettingsList: React.FC<SiteUsersSettingsListProps> = ({ users }) => {
  const { state } = useStore();
  const { currentUser } = state;
  const { t: tComponents } = useTranslation('components');
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            {currentUser?.user?.is_super_admin && <TableCell align='left'>UUID</TableCell>}
            <TableCell>{tComponents('SiteUsersSettings.email')}</TableCell>
            <TableCell align='center'>eKultur {tComponents('SiteUsersSettings.role')}</TableCell>
            {currentUser?.user?.is_super_admin && <TableCell align='center'>noAccess</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(users) &&
            users.map((user) => (
              <TableRow key={user.email}>
                {currentUser?.user?.is_super_admin && <TableCell align='left'>{user.uuid}</TableCell>}
                <TableCell component='th' scope='row'>
                  {user.email}
                </TableCell>
                <TableCell align='center'>{user.role}</TableCell>
                {currentUser?.user?.is_super_admin && (
                  <TableCell align='center'>
                    <Checkbox checked={user.role === 'noAccess'} />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
