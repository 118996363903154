import React, { FC, useCallback } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Container from '../../../components/Container';
import Styles from '../../../assets/js/Styles';
import { ContentViewContentContext, useContentView } from '../ContentViewContext';
import { mergeSx } from '../../../utils/mergeSx';
import { Api, BulkEditBody } from '../../../services/Api';
import { Page } from '../../../declarations/models/Page';
import { useStore } from '../../../components/store/Store';
import { Status } from '../../../declarations/models/Status';

export const PageTreeBulkOperations: FC = () => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { setSelectionMode, selectionState, setPages, setMovePagesMode, movePagesMode, contentContext } =
    useContentView();
  const selectedPageIDs = selectionState.selectedItems?.map((p) => p.id || 0);

  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;

  const buttonSx = {
    whiteSpace: 'nowrap',
    minWidth: 'fit-content',
  };

  const buttonBackgroundSx = {
    backgroundColor: Styles.Colors.LIGHTEST_GREY,
    '&.Mui-disabled': { backgroundColor: Styles.Colors.LIGHTEST_GREY },
  };

  const handleSaveBulk = useCallback(
    async (body: BulkEditBody): Promise<Array<Page> | void> => {
      const result = await Api.bulkEditPages(selectedSiteId, body).fetchDirect(null);
      if (!result) {
        enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
      } else {
        enqueueSnackbar(tCommon('saved'), { variant: 'success' });
        return result.changed;
      }
    },
    [enqueueSnackbar, selectedSiteId, tCommon],
  );

  const updatePageTree = (savedPages: Array<Page>) => {
    setPages((prev: Array<Page>) => {
      const filteredPages = prev.filter((prevPage) => !savedPages.some((savedPage) => savedPage.id === prevPage.id));
      return [...filteredPages, ...savedPages];
    });
  };

  return (
    <Container>
      <Button
        disabled={selectionState.noneSelected || movePagesMode}
        sx={mergeSx(buttonSx, buttonBackgroundSx)}
        variant='contained'
        onClick={() => {
          if (selectedPageIDs?.length) {
            handleSaveBulk({ item_ids: selectedPageIDs, item_type: 'page', status: Status.DRAFT }).then(
              (savedPages) => {
                if (savedPages) {
                  selectionState.deselectAll();
                  setSelectionMode(false);
                  updatePageTree(savedPages);
                }
              },
            );
          }
        }}>
        {tComponents('PageTreeBulkOperations.unpublish')}
      </Button>
      <Button
        disabled={selectionState.noneSelected || movePagesMode}
        sx={mergeSx(buttonSx, buttonBackgroundSx)}
        variant='contained'
        onClick={() => {
          if (selectedPageIDs?.length) {
            handleSaveBulk({ item_ids: selectedPageIDs, item_type: 'page', status: Status.ARCHIVED }).then(
              (savedPages) => {
                if (savedPages) {
                  selectionState.deselectAll();
                  setSelectionMode(false);
                  updatePageTree(savedPages);
                }
              },
            );
          }
        }}>
        {tComponents('PageTreeBulkOperations.archive')}
      </Button>
      <Button
        disabled // TODO: Implement bulk deletion
        sx={mergeSx(buttonSx, buttonBackgroundSx)}
        variant='contained'>
        {tComponents('PageTreeBulkOperations.delete')}
      </Button>
      {contentContext === ContentViewContentContext.PAGE && (
        <Button
          disabled={selectionState.noneSelected}
          sx={mergeSx(buttonSx, buttonBackgroundSx)}
          variant='contained'
          onClick={() => setMovePagesMode((prevState) => !prevState)}>
          {tComponents('PageTreeBulkOperations.movePages')}
        </Button>
      )}
    </Container>
  );
};

export default PageTreeBulkOperations;
