import PageBlockCollapsibleStateSetting from '@/editor/PageEditor/settings/PageBlockCollapsibleStateSetting';
import { DatelineBlock } from '@/editor/PageEditor/EditorBlock/DatelineBlock';
import { DatelineBlockPreview } from '@/editor/PageEditor/previews/DatelineBlockPreview';
import ProgramBlock from '@/editor/PageEditor/EditorBlock/ProgramBlock/ProgramBlock';
import ProgramBlockPreview from '@/editor/PageEditor/previews/ProgramBlockPreview';
import { MapV2Block } from '@/editor/PageEditor/EditorBlock/MapV2Block/MapV2Block';
import { MapV2BlockPreview } from '@/editor/PageEditor/previews/MapV2BlockPreview';
import { EditorContext } from '../declarations/EditorContext';
import { EditorConfiguration } from '../configuration/EditorConfiguration';
import { BlockAction } from '../declarations/BlockAction';
import { SectionType } from '../../../declarations/models/SectionType';
import { BlockType } from '../../../declarations/models/BlockType';
import { EditorDataSet } from '../declarations/EditorDataSet';
import PageSectionDirectionSettings from '../../PageEditor/settings/PageSectionDirectionSettings';
import PageSectionWidthSettings from '../../PageEditor/settings/PageSectionWidthSettings';
import PageSectionSkinSettings from '../../PageEditor/settings/PageSectionSkinSettings';
import { SectionAction } from '../declarations/SectionAction';
import PageBlockSkinSelector from '../../PageEditor/settings/PageBlockSkinSelector';
import PageBlockMediaViewTypeSelector from '../../PageEditor/settings/PageBlockMediaViewTypeSelector';
import DefaultEditorBlock from '../../PageEditor/EditorBlock/DefaultEditorBlock';
import MediaBlock from '../../PageEditor/EditorBlock/MediaBlock/MediaBlock';
import QuoteBlock from '../../PageEditor/EditorBlock/QuoteBlock';
import PageBlockTextPositionSetting from '../../PageEditor/settings/PageBlockTextPositionSetting';
import PageBlockTextSizeSetting from '../../PageEditor/settings/PageBlockTextSizeSetting';
import UnimplementedBlockPreview from '../../PageEditor/previews/UnimplementedBlockPreview';
import DeprecatedBlockPreview from '../../PageEditor/previews/DeprecatedBlockPreview';
import DeprecatedBlock from '../../PageEditor/EditorBlock/DeprecatedBlock';
import WidgetBlock from '../../PageEditor/EditorBlock/WidgetBlock';
import WidgetBlockPreview from '../../PageEditor/previews/WidgetBlockPreview';
import PageBlockPositionSetting from '../../PageEditor/settings/PageBlockPositionSetting';
import BannerBlock from '../../PageEditor/EditorBlock/BannerBlock/BannerBlock';
import { PageBlockBannerSizeSetting } from '../../PageEditor/settings/PageBlockBannerSizeSetting';
import { PageBlockBannerLayoutSetting } from '../../PageEditor/settings/PageBlockBannerLayoutSetting';
import BannerBlockPreview from '../../PageEditor/previews/BannerBlockPreview';
import QuoteBlockPreview from '../../PageEditor/previews/QuoteBlockPreview';
import TextBlock from '../../PageEditor/EditorBlock/TextBlock/TextBlock';
import PageBlockLayoutSetting from '../../PageEditor/settings/PageBlockLayoutSetting';
import PageBlockModuleTypeSetting from '../../PageEditor/settings/PageBlockModuleTypeSetting';
import PageBlockModuleSkinSelector from '../../PageEditor/settings/PageBlockModuleSkinSelector';
import PageBlockTextBlockModuleLayoutSetting from '../../PageEditor/settings/PageBlockModuleLayoutSetting';
import TextBlockPreview from '../../PageEditor/previews/TextBlockPreview';
import MediaBlockPreview from '../../PageEditor/previews/MediaBlockPreview';
import { CardModuleBlock } from '../../PageEditor/EditorBlock/CardModuleBlock/CardModuleBlock';
import PageBlockCardModuleViewTypeSelector from '../../PageEditor/settings/PageBlockCardModuleViewTypeSelector';
import CardModuleBlockPreview from '../../PageEditor/previews/CardModuleBlockPreview';
import LinkListBlock from '../../PageEditor/EditorBlock/LinkListBlock';
import LinkListBlockPreview from '../../PageEditor/previews/LinkListBlockPreview';
import PageBlockLinkListViewSetting from '../../PageEditor/settings/PageBlockLinkListViewSetting';
import ArticleTitleBlock from '../../PageEditor/EditorBlock/ArticleTitleBlock';
import PageBlockArticleTitleTextAlignSetting from '../../PageEditor/settings/PageBlockArticleTitleTextAlignSetting';
import ArticleTitleBlockPreview from '../../PageEditor/previews/ArticleTitleBlockPreview';
import { PageBlockPageListLayoutSetting } from '../../PageEditor/settings/PageBlockPageListLayoutSetting';
import PageBlockPageListViewTypeSelector from '../../PageEditor/settings/PageBlockPageListViewTypeSelector';
import PageListAutoBlock from '../../PageEditor/EditorBlock/PageListBlocks/PageListAutoBlock';
import PageListManualBlock from '../../PageEditor/EditorBlock/PageListBlocks/PageListManualBlock';
import PageListManualBlockPreview from '../../PageEditor/previews/PageBlockPreview/PageListManualBlockPreview';
import TableBlock from '../../PageEditor/EditorBlock/TableBlock/TableBlock';
import TableBlockPreview from '../../PageEditor/previews/TableBlockPreview';
import AttachmentsBlock from '../../PageEditor/EditorBlock/AttachmentBlock/AttachmentsBlock';
import PageBlockAttachmentViewSetting from '../../PageEditor/settings/PageBlockAttachmentsViewSetting';
import AttachmentsBlockPreview from '../../PageEditor/previews/AttachmentsBlockPreview';
import EmployeesAutoBlock from '../../PageEditor/EditorBlock/EmployeeBlocks/EmployeesAutoBlock';
import EmployeesBlockPreview from '../../PageEditor/previews/EmployeesBlockPreview';
import PageBlockEmployeesViewTypeSelector from '../../PageEditor/settings/PageBlockEmployeesViewTypeSelector';
import EmployeesManualBlock from '../../PageEditor/EditorBlock/EmployeeBlocks/EmployeesManualBlock';
import DMAutoBlock from '../../PageEditor/EditorBlock/DMBlocks/DMAutoBlock';
import DMManualBlock from '../../PageEditor/EditorBlock/DMBlocks/DMManualBlock';
import DMBlockAutoPreview from '../../PageEditor/previews/DMBlockPreview/DMBlockAutoPreview';
import DMBlockManualPreview from '../../PageEditor/previews/DMBlockPreview/DMBlockManualPreview';
import DMSingleBlock from '../../PageEditor/EditorBlock/DMBlocks/DMSingleBlock';
import DMBlockSinglePreview from '../../PageEditor/previews/DMBlockPreview/DMBlockSinglePreview';
import PageBlockSingleLayoutSetting from '../../PageEditor/settings/PageBlockSingleLayoutSetting';
import PageBlockDMViewTypeSelector from '../../PageEditor/settings/PageBlockDMViewTypeSelector';
import KPBlock from '../../PageEditor/EditorBlock/KPBlocks/KPBlock';
import KPOwnerBlock from '../../PageEditor/EditorBlock/KPBlocks/KPOwnerBlock';
import KPBlockPreview from '../../PageEditor/previews/KPBlockPreview/KPBlockPreview';
import KPBlockOwnerPreview from '../../PageEditor/previews/KPBlockPreview/KPBlockOwnerPreview';
import PageBlockKPViewTypeSelector from '../../PageEditor/settings/PageBlockKPViewTypeSelector';
import { PageBlockWidthSetting } from '../../PageEditor/settings/PageBlockWidthSetting';
import EventsManualBlock from '../../PageEditor/EditorBlock/PageListBlocks/EventsManualBlock';
import EventsAutoBlock from '../../PageEditor/EditorBlock/PageListBlocks/EventsAutoBlock';
import PageListAutoBlockPreview from '../../PageEditor/previews/PageBlockPreview/PageListAutoBlockPreview';
import EventsManualBlockPreview from '../../PageEditor/previews/PageBlockPreview/EventsManualBlockPreview';
import EventsAutoBlockPreview from '../../PageEditor/previews/PageBlockPreview/EventsAutoBlockPreview';
import FormBlock from '../../PageEditor/EditorBlock/FormBlock/FormBlock';
import FormBlockPreview from '../../PageEditor/previews/FormBlockPreview';
import BylineBlock from '../../PageEditor/EditorBlock/BylineBlock/BylineBlock';
import BylineBlockPreview from '../../PageEditor/previews/BylineBlockPreview';

type Builder = typeof EditorConfiguration.Builder.prototype;

export abstract class EditorConfigurationFactory {
  private static configurePageContext(builder: Builder): void {
    builder
      .withSectionsAvailable(
        SectionType.ARTICLE_HEAD,
        SectionType.ARTICLE_BODY,
        SectionType.ARTICLE_LINKS,
        SectionType.GRID_ROW,
        SectionType.PAGE_GRID,
      )
      .withAllBlocksAvailable()

      .withBlocksInSection(SectionType.ARTICLE_HEAD, BlockType.BYLINE, BlockType.DATELINE)
      .withBlocksInSection(
        SectionType.ARTICLE_BODY,
        BlockType.TEXT,
        BlockType.MEDIA,
        BlockType.RELATIONS,
        BlockType.FRONTPAGE,
        BlockType.QUOTE,
        BlockType.BYLINE,
        // BlockType.MAP,
        BlockType.MAP_V2,
        BlockType.ATTACHMENT,
        BlockType.TABLE,
        BlockType.CARD_SEARCH,
        BlockType.FORM,
        // BlockType.OPENING_HOURS,
        BlockType.PROGRAM,
        // BlockType.REFERENCES,
        // BlockType.SHARING,
        BlockType.WIDGET,
      )
      .withHiddenBlocksInSection(
        SectionType.ARTICLE_BODY,
        BlockType.FORM,
        BlockType.TABLE,
        BlockType.ATTACHMENT,
        BlockType.PROGRAM,
        BlockType.WIDGET,
      )
      .withBlocksInSection(SectionType.ARTICLE_LINKS, BlockType.ADD_CARD)
      .withBlocksInSection(SectionType.GRID_ROW, BlockType.FRONTPAGE)
      .withBlocksInSection(
        SectionType.PAGE_GRID,
        BlockType.PAGE_LIST_AUTO,
        BlockType.PAGE_LIST_MANUAL,
        BlockType.EVENTS_AUTO,
        BlockType.EVENTS_MANUAL,
        BlockType.EMPLOYEES_AUTO,
        BlockType.EMPLOYEES_MANUAL,
        BlockType.DM_AUTO,
        BlockType.DM_MANUAL,
        BlockType.KP,
        BlockType.KP_OWNER,
      )

      .withActionEnabledForAllSections(SectionAction.TOGGLE_SECTION_VISIBILITY)
      .withActionEnabledForAllSectionsExcept(SectionAction.DUPLICATE_SECTION, SectionType.ARTICLE_HEAD)
      .withActionEnabledForAllSectionsExcept(SectionAction.DELETE_SECTION, SectionType.ARTICLE_HEAD)

      .withActionEnabledForAllBlocks(BlockAction.TOGGLE_BLOCK_VISIBILITY)
      .withActionEnabledForAllBlocksExcept(BlockAction.DUPLICATE_BLOCK, BlockType.ARTICLE_TITLE)
      .withActionEnabledForAllBlocksExcept(BlockAction.DELETE_BLOCK, BlockType.ARTICLE_TITLE)
      .withActionEnabledForAllBlocksExcept(BlockAction.COPY_BLOCK, BlockType.ARTICLE_TITLE)
      .withActionEnabledForAllBlocksExcept(BlockAction.CUT_BLOCK, BlockType.ARTICLE_TITLE)

      .withPrefetchedData(EditorDataSet.CATEGORIES, EditorDataSet.SKINS)

      .withSettingForSections(PageSectionDirectionSettings, SectionType.GRID_ROW)
      .withSettingForSections(
        PageSectionWidthSettings,
        SectionType.GRID_ROW,
        SectionType.ARTICLE_BODY,
        SectionType.PAGE_GRID,
      )
      .withSettingForAllSections(PageSectionSkinSettings)

      .withSettingForBlocks(PageBlockCollapsibleStateSetting, BlockType.TEXT, BlockType.TABLE, BlockType.PROGRAM)
      .withSettingForBlocks(
        PageBlockSkinSelector,
        BlockType.MEDIA,
        BlockType.BANNER,
        BlockType.FRONTPAGE,
        BlockType.PAGE_LIST_AUTO,
        BlockType.PAGE_LIST_MANUAL,
        BlockType.EVENTS_AUTO,
        BlockType.EVENTS_MANUAL,
        BlockType.DM_SINGLE,
        BlockType.CARD_SEARCH,
        BlockType.EMPLOYEES_AUTO,
        BlockType.EMPLOYEES_MANUAL,
      )
      .withSettingForBlocks(PageBlockMediaViewTypeSelector, BlockType.MEDIA)
      .withSettingForBlocks(PageBlockAttachmentViewSetting, BlockType.ATTACHMENT)
      .withSettingForBlocks(PageBlockTextPositionSetting, BlockType.QUOTE)
      .withSettingForBlocks(PageBlockTextSizeSetting, BlockType.QUOTE)
      .withSettingForBlocks(PageBlockPositionSetting, BlockType.WIDGET)
      .withSettingForBlocks(PageBlockBannerSizeSetting, BlockType.BANNER)
      .withSettingForBlocks(PageBlockBannerLayoutSetting, BlockType.BANNER, BlockType.FRONTPAGE)
      .withSettingForBlocks(PageBlockBannerSizeSetting, BlockType.FRONTPAGE)
      .withSettingForBlocks(
        PageBlockModuleTypeSetting,
        BlockType.TEXT,
        BlockType.TABLE,
        BlockType.FORM,
        BlockType.PROGRAM,
      )
      .withSettingForBlocks(PageBlockLayoutSetting, BlockType.TEXT)
      .withSettingForBlocks(PageBlockTextBlockModuleLayoutSetting, BlockType.TEXT)
      .withSettingForBlocks(
        PageBlockModuleSkinSelector,
        BlockType.TEXT,
        BlockType.TABLE,
        BlockType.FORM,
        BlockType.PROGRAM,
      )
      .withSettingForBlocks(PageBlockCardModuleViewTypeSelector, BlockType.CARD_SEARCH)
      .withSettingForBlocks(PageBlockLinkListViewSetting, BlockType.RELATIONS)
      .withSettingForBlocks(PageBlockArticleTitleTextAlignSetting, BlockType.ARTICLE_TITLE)
      .withSettingForBlocks(
        PageBlockPageListViewTypeSelector,
        BlockType.PAGE_LIST_AUTO,
        BlockType.PAGE_LIST_MANUAL,
        BlockType.EVENTS_AUTO,
        BlockType.EVENTS_MANUAL,
      )
      .withSettingForBlocks(PageBlockPageListLayoutSetting, BlockType.PAGE_LIST_AUTO, BlockType.PAGE_LIST_MANUAL)
      .withSettingForBlocks(PageBlockEmployeesViewTypeSelector, BlockType.EMPLOYEES_AUTO, BlockType.EMPLOYEES_MANUAL)
      .withSettingForBlocks(PageBlockDMViewTypeSelector, BlockType.DM_AUTO, BlockType.DM_MANUAL)
      .withSettingForBlocks(PageBlockSingleLayoutSetting, BlockType.DM_SINGLE)
      .withSettingForBlocks(PageBlockKPViewTypeSelector, BlockType.KP)
      /*
      .withSettingForBlocks(
        PageBlockWidthSetting,
        BlockType.DM_AUTO,
        BlockType.DM_MANUAL,
        BlockType.KP_OWNER,
        BlockType.KP,
        BlockType.EMPLOYEES_AUTO,
        BlockType.EMPLOYEES_MANUAL,
      )
      */
      .withSettingForBlocks(PageBlockWidthSetting, BlockType.MAP_V2)
      .withPlaceholders(DefaultEditorBlock, UnimplementedBlockPreview)

      .withBlock(BlockType.ARTICLE_TITLE, ArticleTitleBlock, ArticleTitleBlockPreview, true)
      .withBlock(BlockType.ATTACHMENT, AttachmentsBlock, AttachmentsBlockPreview)
      .withBlock(BlockType.BANNER, BannerBlock, BannerBlockPreview, true)
      .withBlock(BlockType.BYLINE, BylineBlock, BylineBlockPreview, true)
      .withBlock(BlockType.CALENDAR, DeprecatedBlock, DeprecatedBlockPreview, true)
      .withBlock(BlockType.CALENDAR_NAVIGATOR, DeprecatedBlock, DeprecatedBlockPreview, true)
      .withBlock(BlockType.CARD, DeprecatedBlock, DeprecatedBlockPreview, true)
      .withBlock(BlockType.CARD_SEARCH, CardModuleBlock, CardModuleBlockPreview)
      .withBlock(BlockType.DATELINE, DatelineBlock, DatelineBlockPreview, true)
      .withBlock(BlockType.DIGITALT_MUSEUM, DeprecatedBlock, DeprecatedBlockPreview, true)
      .withBlock(BlockType.DM_AUTO, DMAutoBlock, DMBlockAutoPreview)
      .withBlock(BlockType.DM_MANUAL, DMManualBlock, DMBlockManualPreview)
      .withBlock(BlockType.DM_SINGLE, DMSingleBlock, DMBlockSinglePreview)
      .withBlock(BlockType.EMPLOYEES_AUTO, EmployeesAutoBlock, EmployeesBlockPreview)
      .withBlock(BlockType.EMPLOYEES_MANUAL, EmployeesManualBlock, EmployeesBlockPreview)
      .withBlock(BlockType.EVENTS_AUTO, EventsAutoBlock, EventsAutoBlockPreview)
      .withBlock(BlockType.EVENTS_MANUAL, EventsManualBlock, EventsManualBlockPreview)
      .withBlock(BlockType.FORM, FormBlock, FormBlockPreview)
      .withBlock(BlockType.FRONTPAGE, BannerBlock, BannerBlockPreview, true)
      .withBlock(BlockType.KP, KPBlock, KPBlockPreview)
      .withBlock(BlockType.KP_OWNER, KPOwnerBlock, KPBlockOwnerPreview)
      .withBlock(BlockType.KULTUR_PUNKT, DeprecatedBlock, DeprecatedBlockPreview, true)
      .withBlock(BlockType.MAP_V2, MapV2Block, MapV2BlockPreview)
      .withBlock(BlockType.MEDIA, MediaBlock, MediaBlockPreview)
      .withBlock(BlockType.OPENING_HOURS, DeprecatedBlock, DeprecatedBlockPreview, true)
      .withBlock(BlockType.PAGE_LIST_AUTO, PageListAutoBlock, PageListAutoBlockPreview)
      .withBlock(BlockType.PAGE_LIST_MANUAL, PageListManualBlock, PageListManualBlockPreview)
      .withBlock(BlockType.PERSON, DeprecatedBlock, DeprecatedBlockPreview, true)
      .withBlock(BlockType.PROGRAM, ProgramBlock, ProgramBlockPreview)
      .withBlock(BlockType.QUOTE, QuoteBlock, QuoteBlockPreview, true)
      .withBlock(BlockType.RELATIONS, LinkListBlock, LinkListBlockPreview)
      .withBlock(BlockType.SEARCH, DeprecatedBlock, DeprecatedBlockPreview, true)
      .withBlock(BlockType.SHARING, DeprecatedBlock, DeprecatedBlockPreview, true)
      .withBlock(BlockType.TABLE, TableBlock, TableBlockPreview)
      .withBlock(BlockType.TEXT, TextBlock, TextBlockPreview, true)
      .withBlock(BlockType.WIDGET, WidgetBlock, WidgetBlockPreview);
  }

  public static makeConfiguration(context: EditorContext): EditorConfiguration {
    const builder: Builder = new EditorConfiguration.Builder(context);
    switch (context) {
      case EditorContext.PAGE:
        EditorConfigurationFactory.configurePageContext(builder);
        break;
      default:
        // eslint-disable-next-line no-console
        console.warn(
          `[EditorConfigurationFactory] Concrete factory for context "${context}" not implemented. Using default values.`,
        );
    }
    return builder.build();
  }
}
