import React, { useEffect, useState } from 'react';
import { BlockPreviewComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { PageListAutoBlock, PageListViewType } from '../../../../declarations/models/blocks/PageListBlocks';
import { Api } from '../../../../services/Api';
import { useStore } from '../../../../components/store/Store';
import { Page } from '../../../../declarations/models/Page';
import PageListBlockPreviewItem from './PageListBlockPreviewItem';

export const PageListAutoBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const { state } = useStore();
  const siteId = state.selectedSite?.id;
  const currentBlock = block as PageListAutoBlock;

  const [pages, setPages] = useState<Array<Page>>([]);

  useEffect(() => {
    if (siteId) {
      const pageId = currentBlock?.searchPathPageId || undefined;
      const params =
        currentBlock.displayType === 'pageTree'
          ? {
              order: currentBlock?.order,
              order_by: currentBlock?.orderBy,
              rows: currentBlock?.maxSearchResults,
              search_level: currentBlock?.searchLevel || 'children',
              // TODO: endpoint must react to "siblings_for_page_id" param
              siblings_for_page_id:
                currentBlock?.searchLevel === 'siblings' ? currentBlock.searchPathPageId || undefined : undefined,
              page_parent_id:
                currentBlock?.searchLevel === 'children' ? currentBlock.searchPathPageId || undefined : undefined,
            }
          : {
              order: currentBlock?.order,
              order_by: currentBlock?.orderBy,
              main_category: currentBlock?.mainCategory,
              sub_categories: currentBlock?.subCategories,
              rows: currentBlock?.maxSearchResults,
              site_ids: currentBlock?.siteIds,
              locale: state.selectedSiteLanguage || 'no',
            };

      const ctx =
        currentBlock.displayType === 'pageTree'
          ? Api.getPagesListPagetree(siteId, pageId, params)
          : Api.getPagesListCategories(params);
      ctx
        .fetchDirect(null)
        .then((res) => setPages(res || []))
        .finally(ctx.abort);
    }
  }, [
    siteId,
    currentBlock.displayType,
    currentBlock.searchPathPageId,
    currentBlock?.order,
    currentBlock?.orderBy,
    currentBlock?.mainCategory,
    currentBlock?.subCategories,
    currentBlock?.maxSearchResults,
    currentBlock?.searchLevel,
    currentBlock?.siteIds,
  ]);

  return (
    <div
      className={`pagelist pagelist--${currentBlock.view || 'grid'} pagelist--${currentBlock?.grid33 ? 'grid33' : ''}`}>
      <div className='pagelist__intro'>
        {block?.visibletitle && block?.title && <h2>{block?.title}</h2>}
        {block?.body && <p>{block?.body}</p>}
      </div>
      {currentBlock?.view !== PageListViewType.LIST ? (
        <>
          <div className='pagelist__grid'>
            {pages.map((page, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <PageListBlockPreviewItem key={`${page.id}-${index}`} autoPage={page} block={currentBlock} />
            ))}
          </div>
          {currentBlock?.showAllLinkPage?.id && (
            <div className='row__footer'>
              <div className='pagelist__intro'>
                <span className='button  button--primary'>
                  {currentBlock?.showAllLinkPage?.local?.title || currentBlock?.showAllLinkPage?.title}
                </span>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='row  row--cards  row--list'>
          <div className='row__grid'>
            <div className='row__content'>
              {pages.map((page, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <PageListBlockPreviewItem key={`${page.id}-${index}`} autoPage={page} block={currentBlock} />
              ))}
            </div>
            {currentBlock?.showAllLinkPage?.id && (
              <div className='row__footer'>
                <div className='pagelist__intro'>
                  <span className='button  button--primary'>
                    <span className='button__label'>
                      {currentBlock?.showAllLinkPage?.local?.title || currentBlock?.showAllLinkPage?.title}
                    </span>
                    <i className='button__icon i-nav-right' />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PageListAutoBlockPreview;
