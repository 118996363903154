import React, { ReactNode } from 'react';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { useCurrentBlock } from '../CurrentBlockProvider';
import ViewTypeSelector from '../../lib/components/settings/ViewTypeSelector';
import { PageListBlock, PageListViewType } from '../../../declarations/models/blocks/PageListBlocks';

const VARIANT_ICONS: Record<PageListViewType, ReactNode> = {
  [PageListViewType.GRID]: <MaterialSymbol name='view_module' />,
  [PageListViewType.LIST]: <MaterialSymbol name='view_list' />,
};

export const PageBlockEmployeesViewTypeSelector: SettingsComponent = () => {
  const { blockPath } = useCurrentBlock();

  const {
    field: { value: selectedViewType, onChange: setSelectedViewType },
  } = useController<BlockSpecificPage<PageListBlock>, `${typeof blockPath}.view`>({ name: `${blockPath}.view` });

  return (
    <ViewTypeSelector
      viewType={selectedViewType}
      viewTypes={Object.values(PageListViewType)}
      onChange={setSelectedViewType}
      variantIcons={VARIANT_ICONS}
      variantTranslationPath='EmployeesViewTypeSelector'
    />
  );
};

export default PageBlockEmployeesViewTypeSelector;
