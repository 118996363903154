import React, { FC, useEffect, useState } from 'react';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { Icon, Typography } from '@mui/material';
import { Status } from '@/declarations/models/Status';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SortSelector } from '@/components/SortSelector';
import { SortDirection, SortOption, SortType } from '@/declarations/models/SortOption';
import Container from '@/components/Container';
import { Site } from '@/declarations/models/Site';
import { Tag } from '@/declarations/models/Tag';
import { useStore } from '../../store/Store';
import { Api } from '../../../services/Api';
import { toggleItem } from '../../../utils/array';
import { ViewType } from '../../../declarations/ViewType';
import Layout from '../../Layout';
import { CardFinderHeader } from './CardFinderHeader';
import FinderLayout from '../FinderLayout';
import MediaList from '../../MediaList/MediaList';
import { GenericMedia } from '../../../declarations/GenericMedia';
import SelectedCardItem from './SelectedCardItem';
import { BlockType } from '../../../declarations/models/BlockType';
import { Card } from '../../../declarations/models/Card';
import { Person } from '../../../declarations/models/Person';

type BlocksSortOptions = SortType.TITLE | SortType.UPDATED_AT;

export interface CardFinderProps {
  open: boolean;
  onClose: () => void;
  onSelectionConfirmed: (selectedItems: Array<GenericMedia<Card | Person>>) => void;
  selectableBlockTypes?: Array<BlockType>;
  multiSelect?: boolean;
}

export const CardFinder: FC<CardFinderProps> = ({
  open,
  onClose,
  onSelectionConfirmed,
  selectableBlockTypes = [BlockType.CARD],
  multiSelect = true,
}) => {
  const store = useStore();
  const site = store?.state?.selectedSite;
  const selectedSiteLanguage = store.state?.selectedSiteLanguage;
  const siteId = site?.id || 0;

  const [page, setPage] = useState<{ start: number; rows: number }>({ start: 0, rows: 20 });
  const resetPage = () => {
    setPage((prevPage) => ({ ...prevPage, start: 0 }));
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedBlockType, _setSelectedBlockType] = useState<BlockType>(
    selectableBlockTypes.length ? selectableBlockTypes[0] : BlockType.CARD,
  );
  const setSelectedBlockType: typeof _setSelectedBlockType = (blockType) => {
    resetPage();
    _setSelectedBlockType(blockType);
  };
  const [cardItems, setCardItems] = useState<Array<GenericMedia<Card | Person>>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedItems, setSelectedItems] = useState<Array<GenericMedia<Card | Person>>>([]);
  const [selectedSharedSite, _setSelectedSharedSite] = useState<Site | null>(null);
  const setSelectedSharedSite: typeof _setSelectedSharedSite = (s) => {
    resetPage();
    _setSelectedSharedSite(s);
  };

  const [selectedSharedLanguage, _setSelectedSharedLanguage] = useState<string | null>(selectedSiteLanguage);
  const setSelectedSharedLanguage: typeof _setSelectedSharedLanguage = (lang) => {
    resetPage();
    _setSelectedSharedLanguage(lang);
  };

  const [sortOption, setSortOption] = useState<SortOption>({ sortBy: SortType.CREATED_AT, order: SortDirection.DESC });

  const [searchQuery, _setSearchQuery] = useState<string>('');
  const setSearchQuery: typeof _setSearchQuery = (query) => {
    resetPage();
    _setSearchQuery(query);
  };

  const [selectedCategories, _setSelectedCategories] = useState<Array<Tag>>([]);
  const setSelectedCategories: typeof _setSelectedCategories = (tags) => {
    resetPage();
    _setSelectedCategories(tags);
  };

  const resetState = () => {
    setSelectedItems([]);
    setSearchQuery('');
    setSelectedSharedSite(null);
    setSelectedSharedLanguage(selectedSiteLanguage);
    setSelectedBlockType(selectableBlockTypes.length ? selectableBlockTypes[0] : BlockType.CARD);
    setPage({ start: 0, rows: 20 });
  };

  const _onClose = () => {
    onClose();
    resetState();
  };

  // If switching to a site that doesn't have the selected language, switch to the first language available.
  useEffect(() => {
    if (selectedSharedSite?.site_contents?.map((c) => c.locale).includes(selectedSharedLanguage ?? '')) return;
    setSelectedSharedLanguage(
      selectedSharedSite?.site_contents?.[0].locale ?? selectedSharedSite?.default_language ?? selectedSiteLanguage,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSharedSite]);

  const isSelected = (item: GenericMedia<Card | Person>): boolean => {
    return selectedItems.some((i) => i.id === item.id);
  };

  const handleSelectionChanged = (item: GenericMedia<Card | Person>) => {
    if (multiSelect) {
      setSelectedItems((selected) => toggleItem(selected, item, (a, b) => a.id === b.id));
    } else {
      setSelectedItems((previouslySelected) =>
        previouslySelected.length === 1 && previouslySelected[0].id === item.id ? [] : [item],
      );
    }
  };

  const handlePageChanged = (changes: Partial<typeof page>) => {
    setPage((prevPage) => ({ ...prevPage, ...changes }));
  };

  const handleSelectionConfirmed: CardFinderProps['onSelectionConfirmed'] = (selected) => {
    _onClose();
    setSelectedItems([]);
    onSelectionConfirmed(selected);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    let unmounted = false;
    setIsLoading(true);

    const ctx = Api.getBlocks(selectedSharedSite?.id ?? siteId, {
      ...page,
      block_type: selectedBlockType,
      text_query: searchQuery,
      locale: selectedSharedLanguage ?? undefined,
      order: sortOption.order,
      order_by: sortOption.sortBy as BlocksSortOptions,
      categories: selectedCategories.map((cat) => cat.tag).join(','),
    });
    ctx
      .fetchDirect(null)
      .then((loadedPage) => {
        if (!unmounted) {
          setTotalCount(loadedPage?.count || 0);
          setCardItems(
            (loadedPage?.items || []).map(
              (item) =>
                ({
                  id: String(item.id || 0),
                  title: item.title,
                  url: item.content?.mediaobject?.url,
                  mimetype: item.content?.mediaobject?.mimetype,
                  source: item,
                } as GenericMedia<Card | Person>),
            ),
          );
        }
      })
      .finally(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      });
    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [
    page,
    selectedSharedLanguage,
    selectedSharedSite,
    siteId,
    open,
    selectedBlockType,
    searchQuery,
    sortOption,
    selectedCategories,
  ]);

  const renderGridItemContent = (item: GenericMedia<Card | Person>) => (
    <Container p={1} left fullWidth>
      {item.source?.page?.title && (
        <Container left fullWidth>
          <PageStatusCircle status={item.source.page.status} />
          <Typography
            variant='caption'
            color={item.source.page.status === Status.PUBLISHED ? 'green' : 'textSecondary'}>
            {item.source.page.title}
          </Typography>
        </Container>
      )}

      {!item.source.page_id && (
        <Container left fullWidth>
          <Icon sx={{ marginLeft: '-10px', width: '40px' }}>
            <MaterialSymbol name='link' />
          </Icon>
          <Typography variant='caption'>{(item.source.content?.url || '#').substring(0, 20)}..</Typography>
        </Container>
      )}
    </Container>
  );

  return (
    <FinderLayout
      open={open}
      selectedItems={selectedItems}
      onConfirmSelection={handleSelectionConfirmed}
      renderSelectedItem={(item) => <SelectedCardItem item={item} onRemoveItem={handleSelectionChanged} />}
      onClose={_onClose}>
      <Layout
        headerContent={
          <CardFinderHeader
            blockType={selectedBlockType}
            onBlockTypeChanged={setSelectedBlockType}
            selectableBlockTypes={selectableBlockTypes}
            onQueryChange={setSearchQuery}
            query={searchQuery}
            tags={selectedCategories}
            onTagsChange={setSelectedCategories}
            site={site}
            selectedSharedSite={selectedSharedSite}
            onSelectSharedSite={setSelectedSharedSite}
            selectedLanguage={selectedSharedLanguage}
            onSelectLanguage={setSelectedSharedLanguage}
          />
        }>
        <MediaList
          customHeaderElement={
            <SortSelector
              availableOptions={[SortType.TITLE, SortType.CREATED_AT]}
              setSortOption={setSortOption}
              sortOption={sortOption}
            />
          }
          renderGridItemContent={renderGridItemContent}
          isLoading={isLoading}
          items={cardItems}
          page={page}
          totalItemCount={totalCount}
          onPageChange={(_, start, rows) => handlePageChanged({ start, rows })}
          onPageSizeChange={(rows) => handlePageChanged({ rows })}
          defaultSelectedViewType={ViewType.GRID}
          getIsSelected={isSelected}
          onSelectChanged={handleSelectionChanged}
          isSelectable
        />
      </Layout>
    </FinderLayout>
  );
};
