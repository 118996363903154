import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import { ViewType } from '../../declarations/ViewType';
import Loader from '../../components/Loader';
import ListView from './MediaViewTypes/ListView';
import GridView from './MediaViewTypes/GridView';
import { M24MediaModel } from '../../declarations/models/M24MediaModel';
import { EditState } from './MediaView';

export interface CommonMediaViewProps {
  items: Array<M24MediaModel>;
  onItemClick: (item: M24MediaModel) => void;
  editState?: EditState;
  getIsSelected?: (item: M24MediaModel) => boolean;
  onSelectChanged?: (item: M24MediaModel, selected: boolean) => void;
  onDeleteItem?: (item: M24MediaModel) => void;
  someSelected?: boolean;
}

interface MediaViewListProps extends CommonMediaViewProps {
  isLoading?: boolean;
  selectedViewType: ViewType;
}

export const MediaViewList = ({
  isLoading = false,
  items,
  selectedViewType = ViewType.GRID,
  someSelected,
  editState,
  ...commonProps
}: MediaViewListProps) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  const renderContent = () => {
    if (isLoading && !items?.length) {
      return (
        <Container fullWidth fullHeight>
          <Loader loadingText={tCommon('loadingContent')} />
        </Container>
      );
    }
    if (!isLoading && !items?.length) {
      return (
        <Container fullWidth fullHeight>
          <Typography color='textSecondary'>{tComponents('MediaList.NoItems')}</Typography>
        </Container>
      );
    }
    switch (selectedViewType) {
      case ViewType.LIST:
        return <ListView {...(commonProps as CommonMediaViewProps)} editState={editState} items={items} />;
      case ViewType.GRID:
        return <GridView {...(commonProps as CommonMediaViewProps)} editState={editState} items={items} />;
    }
    return null;
  };

  return (
    <Container
      fullHeight
      left
      top
      column
      gap={0}
      sx={{ width: `calc(100% - ${editState === EditState.GROUP_UPDATE && someSelected ? 500 : 0}px)` }}>
      {renderContent()}
    </Container>
  );
};

export default MediaViewList;
