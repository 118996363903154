import React, { FC } from 'react';
import Container from '../../../../components/Container';
import Image from '../../../../components/Image';
import { isM24MediaModel } from '../../../../utils/typeChecks';
import { ChildrenProp } from '../../../../declarations/ChildrenProp';
import { Card } from '../../../../declarations/models/Card';
import { Person } from '../../../../declarations/models/Person';

export interface CardModuleBlockItemContentProps {
  item: Card | Person;
}

export const CardModuleBlockItemContent: FC<CardModuleBlockItemContentProps & ChildrenProp> = ({ children, item }) => {
  const altText = isM24MediaModel(item.content?.mediaobject) ? item?.content?.mediaobject?.content?.alttext : '';

  return (
    <Container p={1} pt={0} fullWidth>
      <Container gap={2} top spaceBetween>
        <Container column sx={{ maxWidth: '200px' }} left fullWidth>
          <Image src={item.content?.mediaobject?.url} title={item.title} alt={altText} maxWidth='200px' />
        </Container>
      </Container>
      <Container m={2} fullWidth>
        {children}
      </Container>
    </Container>
  );
};

export default CardModuleBlockItemContent;
