import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import WebpageTrackingSettings from '@/views/SettingsView/WebpageSettingsView/WebpageTrackingSettings';
import WebpageEKulturIntegrationsSettings from '@/views/SettingsView/WebpageSettingsView/WebpageEKulturIntegrationsSettings';
import WebpageIconsSettings from '@/views/SettingsView/WebpageSettingsView/WebpageIconsSettings';
import SaveFormButton from '@/components/SaveFormButton';
import Container from '@/components/Container';
import { Typography } from '@mui/material';
import { ChildrenProp } from '../../../declarations/ChildrenProp';
import BaseSettingsViewForm from '../../BaseSettingsViewForm';
import { Api } from '../../../services/Api';
import { useStore } from '../../../components/store/Store';
import { Site } from '../../../declarations/models/Site';

export const WebpageSettingsView: FC<ChildrenProp> = () => {
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;

  const handleSaveSettingsForm = useCallback(
    async (site: Site): Promise<void | Site> => {
      const savedFormData = await Api.saveSiteSettings<Site>(site).fetchDirect(null);
      if (!savedFormData) {
        enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
      } else {
        enqueueSnackbar(tCommon('saved'), { variant: 'success' });
        return savedFormData;
      }
    },
    [enqueueSnackbar, tCommon],
  );

  const handleLoadModel = () => {
    return Api.getSite(selectedSiteId);
  };

  return (
    <BaseSettingsViewForm<Site>
      title=''
      onSubmit={handleSaveSettingsForm}
      loadModel={handleLoadModel}
      withLayout={false}>
      <Container left fullWidth column p={2}>
        <Container left fullWidth pr={2}>
          <Typography mt={2} variant='h1' fontSize='xx-large'>
            Generelt
          </Typography>
        </Container>
        <Typography variant='h2' fontSize='x-large' mt={2}>
          Sporing
        </Typography>
        <WebpageTrackingSettings />
        <Typography variant='h2' fontSize='x-large' mt={2}>
          E-Kultur integrasjoner
        </Typography>
        <WebpageEKulturIntegrationsSettings />
        <Typography variant='h2' fontSize='x-large' mt={2}>
          Ikoner
        </Typography>
        <WebpageIconsSettings />
        <Container fullWidth right>
          <SaveFormButton />
        </Container>
      </Container>
    </BaseSettingsViewForm>
  );
};

export default WebpageSettingsView;
