import React, { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Container from '../../components/Container';
import { Credit, M24MediaModel } from '../../declarations/models/M24MediaModel';
import { useStore } from '../../components/store/Store';
import { Api } from '../../services/Api';
import { Tag } from '../../declarations/models/Tag';
import { TagType } from '../../declarations/models/TagType';
import MediaEditorHeader from './MediaEditorHeader';
import EditMediaLicenseCreditsFields from './MediaEditorFields/EditMediaLicenseCreditsFields';
import { MediaLicense } from '../../declarations/models/MediaLicense';
import Styles from '../../assets/js/Styles';
import EditMediaTagsField from './MediaEditorFields/EditMediaTagsField';
import EditMediaTextFields from './MediaEditorFields/EditMediaTextFields';
import { isDeepEqual } from '../../utils/object';
import MediaEditorPreview from './MediaEditorPreview';
import { User } from '../../declarations/models/User';

export interface MediaEditorProps {
  resourceId: number;
  onCloseEditor: () => void;
}

const editorStyling = {
  height: '100%',
  overflowY: 'auto',
  gridGap: 4,
  padding: 2,
  borderRadius: 2,
  backgroundColor: Styles.Colors.FINDER_M24_PRIMARY_HEADER_BACKGROUND,
};

export const MediaEditor: FC<MediaEditorProps> = ({ resourceId, onCloseEditor }) => {
  const { state } = useStore();
  const { t: tComp } = useTranslation('components');
  const { enqueueSnackbar } = useSnackbar();
  const siteId = state.selectedSite?.id;
  const [previewOpen, setPreviewOpen] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [savedMedia, setSavedMedia] = useState<M24MediaModel>();
  const [allTags, setAllTags] = useState<Array<Tag>>([]);
  const [uploadedBy, setUploadedBy] = useState<User>();

  const [modifiedMedia, setModifiedMedia] = useState<M24MediaModel>();

  useEffect(() => {
    if (resourceId && siteId) {
      const mediaCtx = Api.getM24MediaResource(siteId, resourceId);
      const tagCtx = Api.getAllTagsForSite(siteId, { type: TagType.TAG });
      mediaCtx
        .fetchDirect(null)
        .catch((e) => console.error(e))
        .then((mediaRes) => {
          if (mediaRes) {
            setSavedMedia(mediaRes);
            // TODO: Include user when retrieving media
            // Api.getUser(mediaRes.created_by_id)
            //   .fetchDirect(null)
            //   .then((user) => user && setUploadedBy(user));
          }
        })
        .then(() =>
          tagCtx
            .fetchDirect([])
            .then((tagsRes) => tagsRes?.length && setAllTags(tagsRes))
            .finally(tagCtx.abort),
        )
        .finally(mediaCtx.abort);
    }
  }, [resourceId, siteId]);

  useEffect(() => setModifiedMedia(savedMedia), [savedMedia]);

  useEffect(() => setIsDirty(!isDeepEqual(savedMedia, modifiedMedia)), [modifiedMedia, savedMedia]);

  const handleSaveMedia = async () => {
    if (siteId && modifiedMedia) {
      const ctx = Api.createM24MediaResource(siteId, modifiedMedia);
      await ctx
        .fetchDirect(null)
        .then((res) => {
          if (res) {
            setSavedMedia(res);
            enqueueSnackbar(tComp('MediaEditor.SaveSuccess'), { variant: 'success' });
          }
        })
        .catch((e) => enqueueSnackbar(tComp('MediaEditor.SaveFailed', { error: e.error || e }), { variant: 'error' }))
        .finally(ctx.abort);
    }
  };

  const handleTitleChange = (title: string, locale?: string) => {
    if (modifiedMedia) {
      if (!locale) {
        setModifiedMedia({
          ...modifiedMedia,
          content: {
            ...modifiedMedia?.content,
            title: title || undefined,
          },
          title: title || undefined,
        } as M24MediaModel);
      } else {
        setModifiedMedia({
          ...modifiedMedia,
          content: {
            ...modifiedMedia?.content,
            [locale]: {
              ...modifiedMedia?.content?.[locale],
              title: title || undefined,
            },
          },
        });
      }
    }
  };

  const handleTextFieldChange = (field: 'caption' | 'alttext', value: string, locale?: string) => {
    if (modifiedMedia) {
      if (!locale) {
        setModifiedMedia({
          ...modifiedMedia,
          content: {
            ...modifiedMedia?.content,
            [field]: value || undefined,
          },
        } as M24MediaModel);
      } else {
        setModifiedMedia({
          ...modifiedMedia,
          content: {
            ...modifiedMedia?.content,
            [locale]: {
              ...modifiedMedia?.content?.[locale],
              [field]: value || undefined,
            },
          },
        });
      }
    }
  };

  const handleLicenseChange = (license: MediaLicense) => {
    if (modifiedMedia) {
      setModifiedMedia({
        ...modifiedMedia,
        content: {
          ...modifiedMedia?.content,
          license_code: license || undefined,
        },
      } as M24MediaModel);
    }
  };

  const handleCreditsChange = (credits: Array<Credit>) => {
    if (modifiedMedia) {
      setModifiedMedia({
        ...modifiedMedia,
        content: {
          ...modifiedMedia?.content,
          credits: credits.length ? credits : undefined,
        },
      } as M24MediaModel);
    }
  };

  const handleTagsChange = (tags: Array<string>) => {
    if (modifiedMedia) {
      setModifiedMedia({
        ...modifiedMedia,
        tags: tags.length ? tags.map((tag) => ({ tag })) : undefined,
      });
    }
  };

  return (
    <Container fullHeight fullWidth column gap={0}>
      <MediaEditorHeader
        mediaItem={savedMedia}
        previewOpen={previewOpen}
        setPreviewOpen={() => setPreviewOpen(!previewOpen)}
        onClose={onCloseEditor}
        onSave={handleSaveMedia}
        isDirty={isDirty}
      />
      <Grid container sx={{ marginTop: '16px', height: 'calc(100% -  56px)' }}>
        <Grid item xs={previewOpen ? 6 : 12} sx={editorStyling}>
          <Container fullWidth fullHeight column top gap={2}>
            <EditMediaTextFields
              item={modifiedMedia}
              setTitle={handleTitleChange}
              setCaption={(value, locale) => handleTextFieldChange('caption', value, locale)}
              setAltText={(value, locale) => handleTextFieldChange('alttext', value, locale)}
              locales={state.selectedSite?.site_contents}
            />
            <EditMediaLicenseCreditsFields
              credits={modifiedMedia?.content?.credits}
              setCredits={handleCreditsChange}
              license={modifiedMedia?.content?.license_code}
              setLicense={handleLicenseChange}
            />
            <EditMediaTagsField
              allTags={allTags}
              tagsInput={modifiedMedia?.tags?.map((tag) => tag.tag)}
              setTagsInput={handleTagsChange}
            />
          </Container>
        </Grid>
        {previewOpen && (
          <Grid item xs={6} height='100%'>
            <MediaEditorPreview media={modifiedMedia} uploadedBy={uploadedBy} siteId={siteId} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default MediaEditor;
