import React, { Dispatch, FC, PropsWithChildren, SetStateAction, useState } from 'react';
import { Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageVersion } from '@/declarations/models/PageVersion';
import { useStore } from '@/components/store/Store';
import { usePageEditorFormState } from '@/editor/PageEditor/PageEditorFormStateProvider';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CustomIcon from '@/components/CustomIcon';
import Styles from '@/assets/js/Styles';
import { useController } from 'react-hook-form';
import Header, { HeaderProps } from '../../../../components/Header';
import EditorBreadcrumbs, { EditorBreadcrumbsProps } from './EditorBreadcrumbs';
import PageEditorSaveButton from '../PageEditorSaveButton';
import { dateToApiTimestamp } from '../../../../utils/dates';
import { Status } from '../../../../declarations/models/Status';
import Container from '../../../../components/Container';
import SkinSelector from '../settings/SkinSelector';
import { ChildrenProp } from '../../../../declarations/ChildrenProp';
import CreatePageModal, { CreatePageParentPage } from '../../../../components/CreatePageModal';
import { useEditorModel } from '../EditorDataProvider';
import { Page } from '../../../../declarations/models/Page';
import { PageCreationSaveType } from '../../../../declarations/models/PageCreationSaveType';
import { ContentViewContentContext } from '../../../../views/ContentView/ContentViewContext';

export interface EditorHeaderProps
  extends Pick<EditorBreadcrumbsProps, 'breadcrumbs'>,
    Pick<HeaderProps, 'menuOpen' | 'onToggleMenuOpen'>,
    ChildrenProp {
  status: Status;
  publishedAt: string | null;
  updatedAt: string | null;
  showLogo?: boolean;
  showMenuButton?: boolean;
  onTogglePageSettingsOpen?: () => void;
  setPages: Dispatch<SetStateAction<Array<Page>>>;
  parentPage?: Page;
  versionDropdown?: React.ReactNode;
  pageVersions?: Array<PageVersion>;
}

export const EditorHeader: FC<PropsWithChildren<EditorHeaderProps>> = ({
  breadcrumbs,
  status,
  publishedAt = null,
  updatedAt = dateToApiTimestamp(Date.now()),
  menuOpen,
  onToggleMenuOpen,
  onTogglePageSettingsOpen,
  showLogo = false,
  showMenuButton = false,
  setPages,
  parentPage,
  versionDropdown,
  pageVersions,
  children,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { state } = useStore();
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const navigate = useNavigate();
  const [createPageModalOpen, setCreatePageModalOpen] = useState<boolean>(false);
  const page = useEditorModel<Page>();
  const { isDirty } = usePageEditorFormState();
  const confirm = useConfirmDialog();
  const [createPageParent, setCreatePageParent] = useState<CreatePageParentPage | null>(null);

  const isEvent = page.main_category === 'event';

  /* TODO: need a better way to get sitedomain + sitepath and need to get "currentVersion" from versionDropdown */
  const baseUrl = page?.base_url || '';
  const pageUrl = `${baseUrl}/${page?.path || ''}`;
  const siteDomain = page?.base_url?.split('/')[0] || '';
  const sitePath = page?.base_url?.split('/')[1] || '';
  const currentVersionId = page.active_version_id;
  let currentVersionCreatedAt = '';
  if (page?.versions && page?.versions[0]) {
    currentVersionCreatedAt = page?.versions[0].created_at || '';
  }
  const selectedVersion = pageVersions?.find((v) => v.id === (page as Page)?.active_version_id);

  let previewUrl = `//${siteDomain}/_m24_/preview?path=${sitePath}/${page?.path}&page_id=${page.id}&version_id=${currentVersionId}&created_at=${selectedVersion?.created_at}`;
  if (page.status === Status.PUBLISHED && selectedVersion?.status === Status.PUBLISHED) {
    previewUrl = `//${page.url || pageUrl}`;
  }

  const {
    field: { value: skinClass, onChange: setSkinClass },
  } = useController<Page, `content.skin`>({ name: `content.skin` });

  const onCreateChildPage = async (parentId: number, parentTitle: string) => {
    setCreatePageParent({
      id: parentId,
      title: parentTitle,
    });
    setCreatePageModalOpen(true);
  };

  const onGoBackClick = () => {
    if (page.is_template) {
      return navigate('/settings/page-templates');
    }
    if (isEvent) {
      return navigate('/events');
    }
    navigate('/content');
  };

  return (
    <Header
      additionalContentLeft={
        <Container fullWidth left>
          <CreatePageModal
            open={createPageModalOpen}
            parentPage={createPageParent}
            closeCreatePageModal={() => setCreatePageModalOpen(false)}
            setPages={setPages}
            saveTypes={[PageCreationSaveType.SAVE_AND_EDIT, PageCreationSaveType.SAVE_AND_CLOSE]}
            onBeforeSave={async (saveType) => {
              if (saveType === PageCreationSaveType.SAVE_AND_EDIT && isDirty) {
                const result = await confirm(tComponents('CreatePageModal.UnsavedChangesWarning'));
                if (!result) {
                  return false;
                }
              }
              return true;
            }}
            contentContext={isEvent ? ContentViewContentContext.EVENT : ContentViewContentContext.PAGE}
          />
          <Container left pl={1}>
            <Tooltip title={tComponents('EditorHeader.GoBack')} arrow>
              <IconButton
                sx={{
                  backgroundColor: Styles.Colors.LIGHT_GREY,
                  border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                  '&:hover': {
                    backgroundColor: Styles.Colors.MEDIUM_LIGHT_GREY,
                  },
                  height: '1em',
                  width: '1em',
                }}
                edge='start'
                aria-label={tAria('components.EditorHeader.GoBack')}
                onClick={onGoBackClick}>
                <MaterialSymbol
                  name='close'
                  sx={{
                    fontSize: 'medium',
                    fontWeight: 700,
                  }}
                />
              </IconButton>
            </Tooltip>
            {page.is_template ? (
              <Typography variant='body2' color='text.secondary' fontWeight={700}>
                {page.title}
              </Typography>
            ) : (
              <EditorBreadcrumbs
                breadcrumbs={breadcrumbs}
                siteId={state.selectedSite?.id}
                locale={state.selectedSiteLanguage ?? undefined}
                onCreateChildPage={isEvent ? undefined : onCreateChildPage}
              />
            )}
            {children}
          </Container>
        </Container>
      }
      additionalContentRight={
        <Container>
          <Container mr={4}>
            <Tooltip
              title={tComponents(`EditorHeader.${page.is_template ? 'pageTemplateSettings' : 'pageSettings'}`)}
              placement='bottom'
              arrow>
              <IconButton
                onClick={onTogglePageSettingsOpen}
                sx={{
                  backgroundColor: Styles.Colors.LIGHT_GREY,
                  border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                  borderRadius: Styles.Dimensions.RADIUS_ROUND_SM,
                  height: '33px',
                }}>
                <CustomIcon name='page_settings' color={Styles.Colors.BLACK} />
              </IconButton>
            </Tooltip>
            <SkinSelector
              availableSkins={state.selectedSiteThemes ?? []}
              selectedSkin={state.selectedSiteThemes?.find((skin) => skin.class === skinClass) || null}
              onSkinChange={(skin) => {
                setSkinClass(skin?.class ?? '');
              }}
              sx={{
                backgroundColor: Styles.Colors.LIGHT_GREY,
                border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                borderRadius: Styles.Dimensions.RADIUS_ROUND_SM,
                height: '33px',
              }}
            />
          </Container>

          <Container right>
            {page.is_template ? (
              <>
                <Tooltip title={tComponents('EditorHeader.TemplateTooltip')}>
                  <Chip
                    label={tCommon('template')}
                    sx={{
                      backgroundColor: Styles.Colors.COPY_PASTE_PURPLE_LIGHT,
                      paddingX: '2rem',
                      marginX: '1rem',
                      textAlign: 'center',
                    }}
                  />
                </Tooltip>

                <PageEditorSaveButton options={[]} />
              </>
            ) : (
              <>
                {versionDropdown}
                <Tooltip title={tComponents(`EditorPreviewContainer.ShowInPortal`)} placement='bottom' arrow>
                  <IconButton onClick={() => window.open(previewUrl, 'portal')}>
                    <MaterialSymbol
                      name='preview'
                      sx={{
                        fontSize: 48,
                        color: Styles.Colors.BLACK,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <PageEditorSaveButton pageVersions={pageVersions} />
              </>
            )}
          </Container>
        </Container>
      }
      menuOpen={menuOpen}
      onToggleMenuOpen={onToggleMenuOpen}
      hideMenuButton={!showMenuButton}
      hideLogo={!showLogo}
      elevation={2}
    />
  );
};

export default EditorHeader;
