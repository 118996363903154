import React from 'react';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { LinkListBlock } from '../../../declarations/models/blocks/LinkListBlock';
import LinksPreview from './LinksPreview';
import { Page } from '../../../declarations/models/Page';
import { LinkExternal, M24MediaLinkItem } from '../../../declarations/models/LinkExternal';
import { useEditorModel } from '../../lib/components/EditorDataProvider';
import { apiTimestampToDate } from '../../../utils/dates';

function sortLinkItemsAlphabetically(
  linkItems: Array<(LinkExternal | M24MediaLinkItem | Page) & { local?: { title?: string; description?: string } }>,
) {
  linkItems?.sort((a, b) => {
    const titleA = (a?.local?.title || a?.title || '').toLowerCase();
    const titleB = (b?.local?.title || b?.title || '').toLowerCase();
    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });
  return linkItems;
}

function sortLinkItemsByPublishedDate(
  linkItems: Array<(LinkExternal | M24MediaLinkItem | Page) & { local?: { title?: string; description?: string } }>,
) {
  linkItems?.sort((a, b) => {
    if ('published_at' in a && a?.published_at) {
      if ('published_at' in b && b?.published_at) {
        if (a?.published_at > b?.published_at) {
          return -1;
        }
        if (a?.published_at < b?.published_at) {
          return 1;
        }
      }
      return -1;
    }
    return 0;
  });
  return linkItems;
}

function filterLinkItemsByLetter(
  linkItems: Array<(LinkExternal | M24MediaLinkItem | Page) & { local?: { title?: string; description?: string } }>,
  letter: string,
) {
  return linkItems?.filter(
    (
      item: (LinkExternal | M24MediaLinkItem | Page) & {
        local?: { title?: string; description?: string };
      },
    ) => {
      if (letter && item) {
        return (item.local?.title || item.title)?.startsWith(letter);
      }
      return false;
    },
  );
}

export const LinkListBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as LinkListBlock;
  const model: Page = useEditorModel<Page>();
  const children = model.children as Array<Page>;
  let linkItems: Array<
    (LinkExternal | M24MediaLinkItem | Page) & { local?: { title?: string; description?: string } }
  > = currentBlock?.listchildren ? (currentBlock?.items as Array<Page>).concat(children) : currentBlock?.items;

  if (currentBlock?.orderBy === '_title') {
    sortLinkItemsAlphabetically(linkItems);
  }

  if (currentBlock?.orderBy === '_date') {
    sortLinkItemsByPublishedDate(linkItems);
  }

  if (currentBlock?.reverseOrder && currentBlock?.groupBy !== 'alpha') {
    linkItems = [...linkItems].reverse();
  }

  let uniqueLetters: Array<string> = [];
  if (currentBlock?.groupBy === 'alpha') {
    const letters: Array<string> = linkItems
      .map((linkItem) => {
        if (linkItem) {
          const title = linkItem.local?.title || linkItem.title || '';
          if (title && title.length > 0) {
            return title.toUpperCase().substring(0, 1);
          }
        }
        return '';
      })
      .filter((v) => v.length > 0);
    if (currentBlock?.reverseOrder) {
      uniqueLetters = letters
        .filter((letter, index) => letters.indexOf(letter) === index)
        .sort((a, b) => b.localeCompare(a));
    } else {
      uniqueLetters = letters
        .filter((letter, index) => letters.indexOf(letter) === index)
        .sort((a, b) => a.localeCompare(b));
    }
  }

  const renderLinksAsList = (
    items: Array<(LinkExternal | M24MediaLinkItem | Page) & { local?: { title?: string; description?: string } }>,
  ) => {
    return <LinksPreview linkItems={items} linkStyle={currentBlock?.linkstyle} showDate={currentBlock?.showDate} />;
  };

  const renderLinksAsTeasers = (
    items: Array<(LinkExternal | M24MediaLinkItem | Page) & { local?: { title?: string; description?: string } }>,
  ) => {
    return items?.map(
      (
        linkItem: (LinkExternal | M24MediaLinkItem | Page) & {
          published_at?: string;
          local?: { title?: string; description?: string };
          image_src?: string;
          description?: string;
          status?: string;
        },
        index,
      ) => {
        return (
          linkItem?.type && (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={`module module--article status--${linkItem?.status}`}>
              <div className='module__grid'>
                {linkItem.image_src && (
                  <div className='module__media'>
                    <img alt='' src={linkItem.image_src} />
                  </div>
                )}
                <div className='module__content'>
                  <header className='module__head'>
                    <strong>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a className='general'>{linkItem?.local?.title || linkItem?.title}</a>
                    </strong>
                  </header>
                  <div className='module__body'>
                    {currentBlock?.showDate && linkItem?.published_at && (
                      <>
                        <i className='meta__icon  i-meta-time' />
                        <time className='meta__time'>
                          {apiTimestampToDate(linkItem?.published_at)?.toLocaleDateString()}
                        </time>
                      </>
                    )}
                    <p>{linkItem.local?.description || linkItem?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        );
      },
    );
  };

  const renderTitle = (full: boolean) => {
    return (
      <>
        {currentBlock?.visibletitle && full && (
          <div className='article__grid'>
            <section className='article__bodytext'>
              <h2>{currentBlock?.title}</h2>
              <p>{currentBlock?.body}</p>
            </section>
          </div>
        )}
        {currentBlock?.visibletitle && !full && (
          <>
            <h2>{currentBlock?.title}</h2>
            <p>{currentBlock?.body}</p>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {!currentBlock?.groupBy && currentBlock?.view === 'list' && (
        <div className='article__grid'>
          <div className='article__bodytext'>
            {renderTitle(false)}
            {renderLinksAsList(linkItems)}
          </div>
        </div>
      )}
      {!currentBlock?.groupBy && currentBlock?.view === 'teasers' && (
        <>
          {renderTitle(true)}
          <div className='section__grid'>
            <section className='row row--articles row--auto'>
              <div className='row__grid'>
                <section className='row__content'>{renderLinksAsTeasers(linkItems)}</section>
              </div>
            </section>
          </div>
        </>
      )}
      {currentBlock?.groupBy === 'alpha' && currentBlock?.view === 'list' && (
        <div className='article__grid'>
          <div className='article__bodytext'>
            {renderTitle(false)}
            {uniqueLetters?.map((letter) => {
              return (
                <div key={letter}>
                  <h3>{letter}</h3>
                  {letter && renderLinksAsList(filterLinkItemsByLetter(linkItems, letter))}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {currentBlock?.groupBy === 'alpha' && currentBlock?.view === 'teasers' && (
        <>
          {renderTitle(true)}
          <div className='section__grid'>
            <section className='row row--articles'>
              <div className='row__grid'>
                <section className='row__content'>
                  {uniqueLetters?.map((letter) => {
                    return (
                      <div key={letter} className='group'>
                        <div className='group__header'>
                          <h3 className='group__title'>{letter}</h3>
                        </div>
                        <div className='group__content'>
                          {letter && renderLinksAsTeasers(filterLinkItemsByLetter(linkItems, letter))}
                        </div>
                      </div>
                    );
                  })}
                </section>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default LinkListBlockPreview;
