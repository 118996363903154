import React, { FC } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grow,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FinderHeader } from '@/components/Finder/FinderHeader';
import { SharedSiteSelector } from '@/components/Finder/SharedSiteSelector';
import { SharingContentType } from '@/declarations/models/SiteSharingModel';
import { Site } from '@/declarations/models/Site';
import Styles from '@/assets/js/Styles';
import { Tag } from '@/declarations/models/Tag';
import { TagType } from '@/declarations/models/TagType';
import { TagsAutocomplete } from '@/components/TagsAutocomplete';
import Container from '../../Container';
import { BlockType } from '../../../declarations/models/BlockType';

export interface CardFinderHeaderProps {
  blockType: BlockType;
  onBlockTypeChanged: (blockType: BlockType) => void;
  selectableBlockTypes: Array<BlockType>;
  onQueryChange: (query: string) => void;
  site: Site | null;
  selectedSharedSite: Site | null;
  onSelectSharedSite: (s: Site | null) => void;
  selectedLanguage: string | null;
  onSelectLanguage: (l: string | null) => void;
  tags?: Array<Tag>;
  onTagsChange?: (tags: Array<Tag>) => void;
  query?: string;
}

export const CardFinderHeader: FC<CardFinderHeaderProps> = ({
  blockType,
  onBlockTypeChanged,
  selectableBlockTypes,
  onQueryChange,
  site,
  selectedSharedSite,
  onSelectSharedSite,
  selectedLanguage,
  onSelectLanguage,
  tags,
  onTagsChange,
  query,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  return (
    <FinderHeader
      title={<Typography sx={{ fontSize: 'xx-large', fontWeight: 500 }}>{tComponents('Finder.CardTitle')}</Typography>}
      topRight={
        <Container right fullWidth>
          <SharedSiteSelector
            siteContext={site}
            selectedSite={selectedSharedSite}
            setSelectedSite={onSelectSharedSite}
            contentType={SharingContentType.CONTENT}
          />
          <Select
            variant='outlined'
            size='small'
            SelectDisplayProps={{ style: { backgroundColor: 'background.paper' } }}
            value={selectedLanguage}
            onChange={(e) => onSelectLanguage(e.target.value as string)}
            sx={{ minWidth: 100, borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT }}>
            {(selectedSharedSite ?? site)?.site_contents?.map((c) => (
              <MenuItem key={c.locale} value={c.locale}>
                {c.locale.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </Container>
      }
      searchField={
        <Container
          gap={2}
          left
          sx={{
            flexGrow: 1,
            flexBasis: '20%',
          }}>
          <TagsAutocomplete
            tagType={TagType.CATEGORY}
            siteId={selectedSharedSite?.id ?? site?.id}
            query={query}
            onQueryChange={onQueryChange}
            tags={tags}
            onTagsChange={onTagsChange}
          />
        </Container>
      }
      bottomRight={
        <Container fullWidth right>
          {selectableBlockTypes.length > 1 ? (
            <FormControl component='fieldset' color='secondary'>
              <FormLabel id='select-block-type-label' component='legend'>
                {tComponents('Finder.Filter.CardType')}
              </FormLabel>
              <RadioGroup
                aria-labelledby='select-blocka-type-label'
                sx={{ display: 'flex', flexFlow: 'row wrap' }}
                value={blockType ?? ''}
                onChange={(_, v) => onBlockTypeChanged((v as BlockType) || null)}>
                {selectableBlockTypes.map((type, i) => (
                  <Grow key={type} timeout={(i + 1) * 100} in>
                    <FormControlLabel
                      value={type}
                      control={<Radio color='secondary' />}
                      label={tCommon(`BlockType.${type}`)}
                    />
                  </Grow>
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <FormLabel id='select-block-type-label' component='legend'>
              {blockType || selectableBlockTypes.length
                ? tCommon(`BlockType.${blockType || selectableBlockTypes[0]}`)
                : 'No block type?'}
            </FormLabel>
          )}
        </Container>
      }
    />

    // <Container column top fullWidth gap={0}>
    //   <Container
    //     sx={(theme) => ({
    //       backgroundColor: Styles.Colors.FINDER_M24_PRIMARY_HEADER_BACKGROUND,
    //       color: theme.palette.getContrastText(Styles.Colors.FINDER_M24_PRIMARY_HEADER_BACKGROUND),
    //     })}
    //     p={2}
    //     spaceBetween
    //     bottom
    //     fullWidth>
    //     <Container fullWidth left>
    //       {selectableBlockTypes.length > 1 ? (
    //         <FormControl component='fieldset' color='secondary'>
    //           <FormLabel id='select-block-type-label' component='legend'>
    //             {tComponents('Finder.Filter.CardType')}
    //           </FormLabel>
    //           <RadioGroup
    //             aria-labelledby='select-blocka-type-label'
    //             sx={{ display: 'flex', flexFlow: 'row wrap' }}
    //             value={blockType ?? ''}
    //             onChange={(_, v) => onBlockTypeChanged((v as BlockType) || null)}>
    //             {selectableBlockTypes.map((type, i) => (
    //               <Grow key={type} timeout={(i + 1) * 100} in>
    //                 <FormControlLabel
    //                   value={type}
    //                   control={<Radio color='secondary' />}
    //                   label={tCommon(`BlockType.${type}`)}
    //                 />
    //               </Grow>
    //             ))}
    //           </RadioGroup>
    //         </FormControl>
    //       ) : (
    //         <FormLabel id='select-block-type-label' component='legend'>
    //           {blockType || selectableBlockTypes.length
    //             ? tCommon(`BlockType.${blockType || selectableBlockTypes[0]}`)
    //             : 'No block type?'}
    //         </FormLabel>
    //       )}
    //     </Container>
    //
    //     <Container right fullWidth gap={2}>
    //       <SearchField fullWidth onQueryChange={onQueryChange} round size='small' />
    //     </Container>
    //   </Container>
    // </Container>
  );
};
