import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useDebugValue,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { Api } from '@/services/Api';
import { Skin } from '@/declarations/models/Skin';
import pkg from '../../../package.json';
import { ActionType } from '../../declarations/ActionType';
import { StoreError } from '../../declarations/StoreError';
import { M24State } from '../../declarations/M24State';
import { StoreAPI } from '../../declarations/StoreAPI';
import { storeReducer } from './reducers';
import { ChildrenProp } from '../../declarations/ChildrenProp';

export interface StoreProps extends ChildrenProp {
  initialState?: Partial<M24State>;
}

const STORE_PREFIX = 'm24.state';
const STATE_TO_PERSIST: Array<{ key: keyof M24State; parse: boolean }> = [
  { key: 'selectedSite', parse: true },
  { key: 'selectedSiteLanguage', parse: false },
  { key: 'selectedSiteDefaultPageId', parse: true },
  { key: 'favouritePages', parse: true },
];

function getInitialState(): M24State {
  const defaultInitialState: M24State = {
    appVersion: pkg.version,
    selectedSite: null,
    selectedSiteLanguage: null,
    selectedSiteDefaultPageId: null,
    currentUser: null,
    favouritePages: {},
    selectedSiteSkins: [],
    selectedSiteThemes: [],
    selectedSiteData: null,
    selectedLanguageSiteData: null,
  };
  return STATE_TO_PERSIST.reduce((state, { key, parse }) => {
    const data = localStorage.getItem(`${STORE_PREFIX}.${String(key)}`);
    if (data) {
      return {
        ...state,
        [key]: parse ? JSON.parse(data) : data,
      };
    }
    return state;
  }, defaultInitialState);
}

/**
 * The react-context for providing the store's API
 */
const StoreAPIContext = createContext<StoreAPI | null>(null);

/**
 * A custom hook for easy access to the store.
 */
export function useStore(): StoreAPI {
  const api = useContext(StoreAPIContext);
  useDebugValue(api?.state);
  if (!api) {
    throw new StoreError('Store accessed before initialized');
  }
  return api;
}

/**
 *
 * @param children
 * @param initialState
 * @constructor
 */
export const Store: FC<StoreProps> = ({ children, initialState = {} }) => {
  const hasInitialized = useRef<boolean>(false);
  const [state, dispatch] = useReducer(storeReducer, { ...getInitialState(), ...initialState });

  const stateRef = useRef<M24State>(state);
  stateRef.current = state;

  /* istanbul ignore next */
  const changeSiteContext = useCallback<StoreAPI['changeSiteContext']>((site, forceRefresh = null) => {
    if (stateRef.current.selectedSite?.id === site?.id && !forceRefresh) {
      return;
    }
    dispatch({
      type: ActionType.CHANGE_SITE_CONTEXT,
      payload: site,
    });
  }, []);

  /* istanbul ignore next */
  const changeSiteLanguage = useCallback<StoreAPI['changeSiteLanguage']>((lng) => {
    dispatch({
      type: ActionType.CHANGE_SITE_CONTEXT_LANGUAGE,
      payload: lng || null,
    });
  }, []);

  /* istanbul ignore next */
  const setCurrentUser = useCallback<StoreAPI['setCurrentUser']>((user) => {
    dispatch({
      type: ActionType.SET_CURRENT_USER,
      payload: user,
    });
  }, []);

  /* istanbul ignore next */
  const toggleFavouritePage = useCallback<StoreAPI['toggleFavouritePage']>((page) => {
    dispatch({
      type: ActionType.TOGGLE_FAVOURITE_PAGE,
      payload: page,
    });
  }, []);

  const isFavourite = useCallback<StoreAPI['isFavourite']>((page) => {
    const favouriteDict = stateRef.current.favouritePages || {};
    return (
      !!page.id &&
      !!page.site_id &&
      !!page.locale &&
      !!favouriteDict[page.site_id] &&
      !!favouriteDict[page.site_id][page.locale] &&
      favouriteDict[page.site_id][page.locale].includes(page.id)
    );
  }, []);

  useEffect(() => {
    // Persist the state every time it changes except on init
    if (!hasInitialized.current) {
      hasInitialized.current = true;
    } else {
      STATE_TO_PERSIST.forEach(({ key, parse }) => {
        const data = state[key];
        const localStorageKey = `${STORE_PREFIX}.${String(key)}`;
        if (!data) {
          localStorage.removeItem(localStorageKey);
        } else {
          localStorage.setItem(localStorageKey, parse ? JSON.stringify(data) : String(data));
        }
      });
    }
  }, [state]);

  useEffect(() => {
    if (state.selectedSite && state.currentUser?.authenticated) {
      dispatch({
        type: ActionType.SET_SITE_THEMES,
        payload: [],
      });
      dispatch({
        type: ActionType.SET_SITE_SKINS,
        payload: [],
      });
      const ctx = Api.getAllDesignsForSite(state.selectedSite.id!);
      ctx.fetchDirect(null).then((res) => {
        const skins: Skin[] = [];
        const themes: Skin[] = [];
        if (res) {
          // eslint-disable-next-line no-restricted-syntax
          for (const design of res) {
            if (Array.isArray(design.skins)) {
              skins.push(...design.skins);
            }
            if (Array.isArray(design.themes)) {
              themes.push(...design.themes);
            }
          }
        }
        dispatch({
          type: ActionType.SET_SITE_THEMES,
          payload: themes,
        });
        dispatch({
          type: ActionType.SET_SITE_SKINS,
          payload: skins,
        });
      });

      return () => {
        ctx.abort();
      };
    }
  }, [state.selectedSite, state.currentUser]);

  useEffect(() => {
    if (stateRef.current.selectedSite?.id && stateRef.current.selectedSiteLanguage) {
      Api.getSiteContent(stateRef.current.selectedSite.id, stateRef.current.selectedSiteLanguage)
        .fetchDirect(null)
        .then((res) => {
          if (res) {
            dispatch({
              type: ActionType.SET_SELECTED_LANGUAGE_DATA,
              payload: res,
            });
          }
        });
    }
  }, [stateRef.current.selectedSite?.id, stateRef.current.selectedSiteLanguage]);

  useEffect(() => {
    if (stateRef.current.selectedSite?.id) {
      Api.getSite(stateRef.current.selectedSite?.id)
        .fetchDirect(null)
        .then((res) => {
          if (res) {
            dispatch({
              type: ActionType.SET_SELECTED_SITE_DATA,
              payload: res,
            });
          }
        });
    }
  }, [stateRef.current.selectedSite?.id]);

  const storeApi = useMemo<StoreAPI>(
    () => ({
      state,
      changeSiteContext,
      setCurrentUser,
      changeSiteLanguage,
      toggleFavouritePage,
      isFavourite,
    }),
    [state, changeSiteContext, changeSiteLanguage, setCurrentUser, toggleFavouritePage, isFavourite],
  );

  return <StoreAPIContext.Provider value={storeApi}>{children}</StoreAPIContext.Provider>;
};

export default Store;
