import React, { useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SelectInput from '@/components/forms/SelectInput';
import RadioInput from '@/components/forms/RadioInput';
import TextInput from '@/components/forms/TextInput';
import DateTimeInput from '@/components/forms/DateTimeInput';
import M24PageFinder from '@/components/Finder/M24PageFinder/M24PageFinder';
import PageListManualBlockItem from '@/editor/PageEditor/EditorBlock/PageListBlocks/PageListManualBlockItem';
import { noop } from '@/utils/functions';
import { LocationSelector } from '@/components/LocationSelector';
import { useStore } from '@/components/store/Store';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import Container from '../../../../components/Container';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { EventCategory } from '../../../../declarations/Category';
import PageAutoSubCategoriesSelect from './PageAutoSubCategoriesSelect';
import SiteSharingBlockSettings from '../SiteSharingBlockSettings';
import Styles from '../../../../assets/js/Styles';
import {
  EventsAutoBlock as EventsAutoBlockModel,
  ShowForPeriodType,
} from '../../../../declarations/models/blocks/EventsBlocks';
import { OutlinedContainer } from '../../../../components/OutlinedContainer';
import EventsDisplayOptions from './EventsDisplayOptions';
import SwitchInput from '../../../../components/forms/SwitchInput';

type FilterType = 'locations' | 'museums' | '';

function getInitialFilterType(block: EventsAutoBlockModel): FilterType {
  if (block.siteIds?.length) {
    return 'museums';
  }
  if (block.locationIds?.length) {
    return 'locations';
  }
  return '';
}

export const settingsAreaStyle = {
  width: '100%',
  backgroundColor: Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR,
  padding: 2,
  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
  gap: 2,
};

export const EventsAutoBlock: BlockComponent = () => {
  const { state } = useStore();
  const { blockPath, block } = useCurrentBlock<EventsAutoBlockModel>();
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const [pageFinderOpen, setPageFinderOpen] = useState<boolean>(false);

  const currentBlock = block as EventsAutoBlockModel;

  const {
    field: { value: subCategories, onChange: onChangeSubCategories },
  } = useController<BlockSpecificPage<EventsAutoBlockModel>, `${BlockPath}.subCategories`>({
    name: `${blockPath}.subCategories`,
  });

  const {
    field: { value: siteIds, onChange: setSiteIds },
  } = useController<BlockSpecificPage<EventsAutoBlockModel>, `${BlockPath}.siteIds`>({ name: `${blockPath}.siteIds` });

  const {
    field: { value: userMuseumFilter, onChange: setUserMuseumFilter },
  } = useController<BlockSpecificPage<EventsAutoBlockModel>, `${BlockPath}.userMuseumFilter`>({
    name: `${blockPath}.userMuseumFilter`,
  });

  const {
    field: { value: userCategoryFilter, onChange: setUserCategoryFilter },
  } = useController<BlockSpecificPage<EventsAutoBlockModel>, `${BlockPath}.userCategoryFilter`>({
    name: `${blockPath}.userCategoryFilter`,
  });

  const {
    field: { value: userTargetGroupFilter, onChange: setUserTargetGroupFilter },
  } = useController<BlockSpecificPage<EventsAutoBlockModel>, `${BlockPath}.userTargetGroupFilter`>({
    name: `${blockPath}.userTargetGroupFilter`,
  });

  const {
    field: { value: userDateFilter, onChange: setUserDateFilter },
  } = useController<BlockSpecificPage<EventsAutoBlockModel>, `${BlockPath}.userDateFilter`>({
    name: `${blockPath}.userDateFilter`,
  });

  const {
    field: { value: userTodayFilter, onChange: setUserTodayFilter },
  } = useController<BlockSpecificPage<EventsAutoBlockModel>, `${BlockPath}.userTodayFilter`>({
    name: `${blockPath}.userTodayFilter`,
  });

  const {
    field: { value: showAllLinkPage, onChange: onChangeShowAllLinkPage },
  } = useController<BlockSpecificPage<EventsAutoBlockModel>, `${BlockPath}.showAllLinkPage`>({
    name: `${blockPath}.showAllLinkPage`,
  });

  const showForPeriodType = useWatch<BlockSpecificPage<EventsAutoBlockModel>, `${BlockPath}.showForPeriodType`>({
    name: `${blockPath}.showForPeriodType`,
  });

  const {
    field: { value: locationIds, onChange: setLocationIds },
  } = useController<BlockSpecificPage<EventsAutoBlockModel>, `${BlockPath}.locationIds`>({
    name: `${blockPath}.locationIds`,
  });

  const [userFiltering, setUserFiltering] = useState<boolean>(
    !!userMuseumFilter || !!userCategoryFilter || !!userDateFilter || !!userTodayFilter || !!userTargetGroupFilter,
  );
  const [filterType, _setFilterType] = useState<FilterType>(getInitialFilterType(block));
  const setFilterType: typeof _setFilterType = (v) => {
    _setFilterType(v);
    setLocationIds(null);
    setSiteIds(null);
  };

  const handleChangeUserFiltering = () => {
    if (userFiltering) {
      setUserMuseumFilter(false);
      setUserCategoryFilter(false);
      setUserDateFilter(false);
      setUserTodayFilter(false);
      setUserTargetGroupFilter(false);
    }
    setUserFiltering(!userFiltering);
  };
  const gridSizeOptions = ['auto', '3', '4', '5', '6'];
  const mobileGridOptions = ['auto', 'grid', 'list'];

  return (
    <Container fullWidth column gap={2} left>
      <M24PageFinder
        hideSiteSelector
        open={pageFinderOpen}
        onClose={() => setPageFinderOpen(false)}
        onSelectionConfirmed={(pages) => {
          if (pages.length > 0) {
            onChangeShowAllLinkPage(pages[0].source);
          }
        }}
      />

      <Box sx={settingsAreaStyle}>
        {tComponents('EventsBlock.Rules')}:
        <OutlinedContainer mb={1} mt={1} fullWidth column left>
          <PageAutoSubCategoriesSelect
            subCategories={subCategories}
            setSubCategories={onChangeSubCategories}
            subOptions={Object.values(EventCategory)}
          />
        </OutlinedContainer>
        <OutlinedContainer mb={1} fullWidth column left>
          <FormControlLabel
            labelPlacement='top'
            sx={{ alignItems: 'flex-start' }}
            control={
              <RadioGroup value={filterType} onChange={(_, v) => setFilterType(v as FilterType)} row>
                <FormControlLabel value='' control={<Radio />} label={tCommon('none')} />
                <FormControlLabel
                  value='museums'
                  control={<Radio />}
                  label={tComponents('EventsBlock.UseMuseumFiltering')}
                />
                <FormControlLabel
                  value='locations'
                  control={<Radio />}
                  label={tComponents('EventsBlock.UseLocationFiltering')}
                />
              </RadioGroup>
            }
            label={<FormLabel>{tComponents('EventsBlock.UseFilteringLabel')}</FormLabel>}
          />
          {filterType === 'locations' && (
            <LocationSelector
              siteId={state.selectedSite?.id}
              value={locationIds?.split(',').map(Number) ?? []}
              onChange={(v) => setLocationIds(v.join(','))}
            />
          )}
          {filterType === 'museums' && <SiteSharingBlockSettings siteIds={siteIds} setSiteIds={setSiteIds} />}
        </OutlinedContainer>
        <OutlinedContainer mb={1} fullWidth column left>
          <Container fullWidth left top mt={2} column>
            <RadioInput
              required
              options={[...ShowForPeriodType]}
              getOptionKey={String}
              getOptionLabel={(key) => tComponents(`EventsBlock.ShowForPeriodType.${key}`)}
              path={`${blockPath}.showForPeriodType`}
              defaultValue='3m'
              label={tComponents('EventsBlock.ShowForPeriodLabel')}
              direction='horizontal'
            />
            {showForPeriodType === 'specify' && (
              <Container mt={2}>
                <DateTimeInput path={`${blockPath}.showForPeriodStart`} label={tCommon('from')} inputType='date' />
                –
                <DateTimeInput path={`${blockPath}.showForPeriodEnd`} label={tCommon('to')} inputType='date' />
              </Container>
            )}
            <Container mt={4}>
              <TextInput
                fullWidth={false}
                path={`${blockPath}.maxSearchResults`}
                label={tComponents('EventsBlock.MaxItemsLabel')}
                type='number'
              />

              <SwitchInput
                path={`${blockPath}.disableExpandedDates`}
                label={tComponents('EventsBlock.DisableExpandedDates')}
              />
            </Container>
          </Container>

          {!currentBlock.view?.includes('list') && (
            <Container fullWidth left gap={2} mt={2} mb={2}>
              <SelectInput
                hideNoSelectionOption
                defaultValue='auto'
                options={gridSizeOptions}
                getOptionKey={(gridSize) => `size-${gridSize}`}
                getOptionLabel={(gridSize) => `${gridSize}`}
                path={`${blockPath}.gridSize`}
                label={tComponents('CardModuleBlock.GridSizeLabel')}
              />

              <SelectInput
                hideNoSelectionOption
                defaultValue='auto'
                options={mobileGridOptions}
                getOptionKey={(mobileGrid) => `mobileGrid-${mobileGrid}`}
                getOptionLabel={(mobileGrid) => `${mobileGrid}`}
                path={`${blockPath}.gridMobileBehaviour`}
                label={tComponents('CardModuleBlock.GridMobileBehaviourLabel')}
              />
            </Container>
          )}
        </OutlinedContainer>
      </Box>

      <Container sx={settingsAreaStyle}>
        <EventsDisplayOptions blockPath={blockPath} />
      </Container>

      <Container sx={settingsAreaStyle}>
        <Container column left fullWidth>
          <Typography variant='subtitle2'>{tComponents('EventsBlock.ShowAllLinkPageTitle')}</Typography>
          {showAllLinkPage && (
            <PageListManualBlockItem
              index={0}
              onReorder={noop}
              onDelete={() => onChangeShowAllLinkPage(null)}
              overrideItemPath={`${blockPath}.showAllLinkPage`}
              color='primary'
              hideHighlightCheckbox
              hideSkinSelector
              disableReordering
            />
          )}
          <Button onClick={() => setPageFinderOpen(true)} variant='contained' color='secondary'>
            {tComponents('EventsBlock.ShowAllLinkPageButtonLabel')}
          </Button>
        </Container>
      </Container>

      <Container sx={settingsAreaStyle}>
        <OutlinedContainer fullWidth column left>
          <FormControlLabel
            control={<Switch checked={userFiltering} onChange={handleChangeUserFiltering} color='secondary' />}
            label={tComponents('EventsBlock.UserFilteringLabel')}
          />
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={!!userMuseumFilter} onChange={(_, v) => setUserMuseumFilter(v)} />}
              disabled={!userFiltering}
              label={tComponents('EventsBlock.MuseumFilteringLabel')}
            />
            <FormControlLabel
              control={<Checkbox checked={!!userCategoryFilter} onChange={(_, v) => setUserCategoryFilter(v)} />}
              disabled={!userFiltering}
              label={tComponents('EventsBlock.CategoriesFilteringLabel')}
            />
            <FormControlLabel
              control={<Checkbox checked={!!userTargetGroupFilter} onChange={(_, v) => setUserTargetGroupFilter(v)} />}
              disabled={!userFiltering}
              label={tComponents('EventsBlock.TargetGroupsFilteringLabel')}
            />
            <FormControlLabel
              control={<Checkbox checked={!!userDateFilter} onChange={(_, v) => setUserDateFilter(v)} />}
              disabled={!userFiltering}
              label={tComponents('EventsBlock.DateFilteringLabel')}
            />
            <FormControlLabel
              control={<Checkbox checked={!!userTodayFilter} onChange={(_, v) => setUserTodayFilter(v)} />}
              disabled={!userFiltering}
              label={tComponents('EventsBlock.TodayFilteringLabel')}
            />
          </FormGroup>
        </OutlinedContainer>
      </Container>
    </Container>
  );
};

export default EventsAutoBlock;
