import React, { FC, ReactNode } from 'react';
import { Box, Button, ButtonBase, Checkbox, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next, { TFunction } from 'i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '../../../assets/js/Styles';
import Container from '../../../components/Container';
import { M24MediaModel, MediaUsages } from '../../../declarations/models/M24MediaModel';
import { CommonMediaViewProps } from '../MediaViewList';
import { formatAPITimestamp } from '../../../utils/dates';
import { EditState } from '../MediaView';
import { MediaResourceType } from '../../../declarations/models/MediaResourceType';

export const getImage = (item: M24MediaModel, height: number, width: number) => {
  const ICON_IMAGE_STYLE = { width: '64px', height: '64px', margin: `${(height - 64) / 2}px ${(width - 64) / 2}px` };
  let imgMimeType = '';
  if (item?.mimetype?.includes('png')) {
    imgMimeType = `mediaType=${item?.mimetype}`;
  }
  switch (item.type) {
    case MediaResourceType.IMAGE:
    case MediaResourceType.ANIMATION:
    case MediaResourceType.VIDEO:
      return (
        <img
          src={`${item?.url}?${imgMimeType}&dimension=250x250`}
          title={item.filename}
          alt={item.content?.alttext}
          loading='lazy'
          style={{
            width: `${width}px`,
            height: `${height}px`,
            objectFit: 'contain',
            background: Styles.Colors.MEDIUM_DARK_GREY,
            overflow: 'hidden',
            flex: 1,
          }}
        />
      );
    case MediaResourceType.AUDIO:
      return <MaterialSymbol name='music_note' sx={ICON_IMAGE_STYLE} />;
    case MediaResourceType.DOCUMENT:
      return <MaterialSymbol name='description' sx={ICON_IMAGE_STYLE} />;
    default:
      return <MaterialSymbol name='image' sx={ICON_IMAGE_STYLE} />;
  }
};

export const getUsageText = (t: TFunction, mediaUsages?: MediaUsages) => {
  if (
    !mediaUsages?.page_usages?.length &&
    !mediaUsages?.card_usages?.length &&
    !mediaUsages?.person_usages?.length &&
    !mediaUsages?.other_usages?.length &&
    !mediaUsages?.employee_usages?.length
  ) {
    return (
      <Typography fontStyle='italic' color={Styles.Colors.MEDIUM_DARK_GREY}>
        {t('MediaView.NotInUse') as ReactNode}
      </Typography>
    );
  }
  return (
    <Typography>
      {
        t(`MediaView.InUse`, {
          pages: mediaUsages.page_usages?.length ?? 0,
          cards: mediaUsages.card_usages?.length ?? 0,
          persons: (mediaUsages.person_usages?.length ?? 0) + (mediaUsages.employee_usages?.length ?? 0),
          others: mediaUsages.other_usages?.length ?? 0,
        }) as ReactNode
      }
    </Typography>
  );
};

export interface MediaViewItemProps
  extends Pick<CommonMediaViewProps, 'onItemClick' | 'editState' | 'onSelectChanged' | 'onDeleteItem'> {
  item: M24MediaModel;
  isSelected?: boolean;
}

export const iconButtonStyle = {
  position: 'absolute',
  margin: '8px',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
};

const smallScrollBarStyle = (maxTagRows: number) => ({
  padding: '4px 0',
  maxHeight: `${maxTagRows * 30}px`,
  overflowX: 'auto',
  '::-webkit-scrollbar': { width: '8px' },
  '::-webkit-scrollbar-track': { backgroundColor: 'transparent' },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
  },
  '::-webkit-scrollbar-thumb:hover': { backgroundColor: '#555' },
});

export const GridViewItem: FC<MediaViewItemProps> = ({ item, onItemClick, isSelected, editState, onDeleteItem }) => {
  const { t } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const license = item.content?.license_code;
  const credits = item.content?.credits;
  const { tags } = item;
  const licCount = license ? 1 : 0;
  const maxCredCount = 4 - licCount - (tags?.length ? 1 : 0);
  const credCount = credits && credits?.length > maxCredCount ? maxCredCount : credits?.length ?? 0;
  const maxTagRows = 5 - licCount - credCount;
  const enContent = item.content?.en;
  const isEn = i18next.language === 'en';

  return (
    <Container
      column
      sx={{
        outline: isSelected ? `4px solid ${Styles.Colors.MEDIA_ITEM_SELECTED_COLOR}` : undefined,
        borderRadius: Styles.Dimensions.RADIUS_ROUND_SM,
        backgroundColor: Styles.Colors.LIGHT_GREY,
        transition: 'all 150ms ease',
        width: '270px',
        minHeight: '290px',
        '&:hover': { backgroundColor: Styles.Colors.MEDIUM_LIGHT_GREY },
      }}>
      <Container
        fullWidth
        fullHeight
        column
        gap={0}
        sx={{
          flexGrow: 1,
        }}>
        <Container left sx={{ position: 'relative', flexGrow: 1 }}>
          <ButtonBase
            onClick={() => onItemClick(item)}
            sx={{
              backgroundColor: Styles.Colors.MEDIUM_DARK_GREY,
            }}>
            {getImage(item, 170, 204)}
          </ButtonBase>
          {editState !== EditState.SINGLE && (
            <Checkbox
              checked={isSelected}
              color='success'
              sx={{ ...iconButtonStyle, padding: 0, borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT, top: 25 }}
              onClick={() => onItemClick(item)}
            />
          )}
        </Container>
        <Container fullWidth column left p={1} gap={0}>
          <Typography
            width='100%'
            align='left'
            noWrap
            fontWeight={700}
            sx={{
              textDecoration: 'underline',
            }}>
            {isEn && enContent?.title ? enContent.title : item.title || item.content?.title || item.filename}
          </Typography>
          {editState === EditState.SINGLE && (
            <>
              <Typography
                maxWidth='100%'
                noWrap
                sx={{
                  fontSize: 12,
                  color: Styles.Colors.DARK_GREY,
                }}>
                {formatAPITimestamp(item.updated_at, 'datetime')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <Button onClick={() => onDeleteItem?.(item)} endIcon={<MaterialSymbol name='delete' fill />}>
                  {tCommon('delete')}
                </Button>
                {!(isEn ? enContent?.alttext : item?.content?.alttext) ? (
                  <Box display='flex' gap={0.5} alignItems='center'>
                    <MaterialSymbol
                      name='error'
                      fill
                      color='error'
                      sx={{
                        fontSize: 14,
                      }}
                    />
                    <Typography color='error' fontSize={14}>
                      {t('MediaView.MissingAlt')}
                    </Typography>
                  </Box>
                ) : (
                  '\u00A0'
                )}
              </Box>
            </>
          )}
          {editState === EditState.GROUP_UPDATE && (
            <>
              {!!license && (
                <Typography maxWidth='100%' noWrap>
                  <b>{t('MediaView.License')}: </b>
                  {license.toUpperCase()}
                </Typography>
              )}
              {credits?.slice(0, credCount).map(
                (credit, i) =>
                  !!credit.type && ( // eslint-disable-next-line react/no-array-index-key
                    <Typography key={`${i}-${credit.type}`} maxWidth='100%' noWrap>
                      <b>{t(`MediaView.CreditTypes.${credit.type}`)}: </b>
                      {credit.name}
                    </Typography>
                  ),
              )}
              <Container left gap={0.5} wrap sx={smallScrollBarStyle(maxTagRows)}>
                {tags?.length
                  ? tags.map((n) => <Chip key={n.tag} color='success' label={n.tag} variant='outlined' size='small' />)
                  : '\u00A0'}
              </Container>
            </>
          )}
          {editState === EditState.GROUP_DELETE && ( // FIXME: get usage data
            <Container column fullWidth>
              {getUsageText(t, item.media_usages)}
            </Container>
          )}
        </Container>
      </Container>
    </Container>
  );
};

export default GridViewItem;
