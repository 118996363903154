import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  FormModule,
  FormModuleInput,
  FormSection,
  OptionsFormBlock,
  UserFormBlock,
  YesNoFormBlock,
} from '@/declarations/models/FormModule';
import { Api } from '@/services/Api';
import M24FormEditorHeader from '@/editor/M24FormEditor/M24FormEditorHeader';
import M24FormEditorPreviewWrapper from '@/editor/M24FormEditor/M24FormEditorPreviewWrapper';
import Form from '@/components/forms/Form';
import Container from '@/components/Container';
import EditorLayout from '@/editor/lib/components/EditorLayout';
import Styles from '@/assets/js/Styles';
import EditorEventManager from '@/editor/lib/eventManager/EditorEventManager';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import TextInput from '@/components/forms/TextInput';
import { useEditorModel } from '@/editor/lib/components/EditorDataProvider';
import { Status } from '@/declarations/models/Status';
import CheckboxInput from '@/components/forms/CheckboxInput';
import SelectInput from '@/components/forms/SelectInput';
import { useStore } from '@/components/store/Store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import DeleteButton from '@/components/DeleteButton';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import MainMenu from '@/components/MainMenu';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { WysiwygEditor } from '@/editor/PageEditor/EditorBlock/TextBlock/WysiwygEditor';
import { SaveType } from '../lib/declarations/SaveType';

interface AccordionContextValue {
  settingsOpen?: boolean;
  setSettingsOpen: (open?: boolean) => void;
  sectionIndexOpen?: number;
  blockIndexOpen?: number;
  setSectionIndexOpen: (index?: number) => void;
  setBlockIndexOpen: (index?: number) => void;
}
const AccordionContext = createContext<AccordionContextValue | null>(null);

export const useM24FormEditorAccordionContext = () => {
  const c = useContext(AccordionContext);
  if (!c) {
    throw new Error('useM24FormEditorAccordionContext must be used within a AccordionProvider');
  }
  return c;
};

function getBlockLabel({
  type,
  title,
  label,
  subtype,
}: {
  type: string;
  title?: string;
  label?: string;
  subtype?: string;
}) {
  const subtitle = label ?? title;
  return (
    <Box display='flex' gap={1} alignItems='baseline'>
      <Typography
        sx={{
          fontWeight: 'bold',
        }}>
        {type}
      </Typography>
      {subtype && <Typography variant='caption'>{subtype}</Typography>}
      {subtype && subtitle && <Typography variant='caption'> - </Typography>}
      {subtitle && <Typography variant='caption'>{subtitle}</Typography>}
    </Box>
  );
}

/**
 * Simple wrapper around Accordion with common settings for block elements (inputs)
 * @param children
 * @constructor
 */
const BlockAccordion: FC<AccordionProps> = ({ children, ...accordionProps }) => {
  const theme = useTheme();
  const backgroundColor = Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR;
  const textColor = theme.palette.getContrastText(backgroundColor);
  return (
    <Box mt={1} mb={2}>
      <Accordion
        {...accordionProps}
        sx={{
          width: '100%',
          backgroundColor,
          color: textColor,
          borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
        }}
        elevation={0}>
        {children}
      </Accordion>
    </Box>
  );
};
const BlockAccordionSummary: FC<
  PropsWithChildren & {
    onDelete?: () => void;
    hidden?: boolean;
    onToggleHidden?: (hidden: boolean) => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    hideMoveButtons?: boolean;
    expanded?: boolean;
  }
> = ({ children, onDelete, hidden = false, onToggleHidden, onMoveDown, onMoveUp, hideMoveButtons, expanded }) => {
  const { t: tCommon } = useTranslation('common');
  const theme = useTheme();
  return (
    <Box display='flex' overflow='hidden'>
      <AccordionSummary
        sx={{
          flexGrow: 1,
          flexDirection: 'row-reverse',
          minHeight: theme.spacing(6),
          pr: 2,
          width: '100%',
        }}
        expandIcon={
          <MaterialSymbol
            name='chevron_right'
            color='secondary'
            sx={expanded ? { transform: 'rotate(-90deg)' } : undefined}
          />
        }>
        {children}
      </AccordionSummary>
      <Box display='flex'>
        {onToggleHidden && (
          <Tooltip title={hidden ? tCommon('show') : tCommon('hide')} placement='top'>
            <IconButton
              onClick={() => onToggleHidden(!hidden)}
              sx={{
                color: hidden ? theme.palette.grey[500] : theme.palette.grey[900],
              }}>
              {hidden === false ? (
                <MaterialSymbol name='visibility' fill />
              ) : (
                <MaterialSymbol name='visibility_off' fill />
              )}
            </IconButton>
          </Tooltip>
        )}
        {onDelete && (
          <DeleteButton onConfirm={onDelete} component='IconButton' tooltip={tCommon('delete')} iconColor='action' />
        )}
        {hideMoveButtons !== true && (
          <>
            <Tooltip title={tCommon('moveUp')} placement='top'>
              <span style={{ display: 'contents' }}>
                <IconButton disabled={!onMoveUp} onClick={onMoveUp}>
                  <MaterialSymbol name='chevron_right' sx={{ transform: 'rotate(-90deg)' }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={tCommon('moveDown')} placement='top'>
              <span style={{ display: 'contents' }}>
                <IconButton disabled={!onMoveDown} onClick={onMoveDown}>
                  <MaterialSymbol name='chevron_right' sx={{ transform: 'rotate(90deg)' }} />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </Box>
    </Box>
  );
};

const SubTypeSelector = ({
  blockPath,
  subtypes,
  getLabel,
  defaultValue,
}: {
  blockPath: string;
  subtypes: string[];
  getLabel?: (item: string) => string;
  defaultValue?: string;
}) => {
  const {
    field: { value: subtypeValue, onChange: onChangeSubType },
  } = useController({
    name: blockPath,
    defaultValue,
  });

  return (
    <FormControl fullWidth style={{ marginBottom: '10px' }}>
      <RadioGroup value={subtypeValue} onChange={onChangeSubType} row>
        {subtypes.map((subtype) => (
          <FormControlLabel
            key={subtype}
            control={<Radio />}
            value={subtype}
            label={getLabel ? getLabel(subtype) : subtype}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

/**
 * when "input" is of type content (not really a input, but it lives in the inputs array)
 * @param block
 * @param blockPath
 * @constructor
 */
const ContentBlock = ({ blockPath }: BlockProps) => {
  const {
    field: { value: bodyText, onChange: onChangeBodyText },
  } = useController({
    name: `${blockPath}.text`,
  });

  return (
    <>
      <TextInput variant='filled' path={`${blockPath}.title`} label='Tittel' />
      <WysiwygEditor value={bodyText} onChange={onChangeBodyText} type='tinymce' />
    </>
  );
};

const ButtonBlock = ({ blockPath }: BlockProps) => {
  const subtypes = ['', 'prev', 'next', 'reset'];
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  return (
    <>
      <SubTypeSelector
        blockPath={`${blockPath}.subtype`}
        subtypes={subtypes}
        getLabel={(subtype) => {
          if (subtype === '') {
            return tComponents('FormEditor.button.defaultBlockLabel');
          }
          return tComponents(`FormEditor.button.${subtype}BlockLabel`);
        }}
        defaultValue=''
      />
      <TextInput variant='filled' path={`${blockPath}.label`} label={tCommon('inputLabel')} />
    </>
  );
};

const LabelAndRequiredInput: FC<
  PropsWithChildren<{ path: string; label: string; hideRequiredToggle?: boolean; requiredLabel?: string }>
> = ({ children, path, label, hideRequiredToggle, requiredLabel }) => {
  const { t: tCommon } = useTranslation('common');
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={1}
      p={2}
      border={`1px solid ${Styles.Colors.THEME_BG_COLOR_SECONDARY}`}>
      <Box>
        <TextInput variant='filled' path={`${path}.label`} label={label} />
      </Box>
      <Box display='flex' flexWrap='wrap'>
        {!hideRequiredToggle && (
          <CheckboxInput path={`${path}.required`} label={requiredLabel || tCommon('required')} />
        )}
        {children}
      </Box>
    </Box>
  );
};

const UserBlock = ({ block, blockPath }: BlockProps & { block: UserFormBlock }) => {
  const subtypes: (typeof block)['subtype'][] = ['minimum', 'simple', 'complete'];
  const { t: tComponents } = useTranslation('components');
  return (
    <>
      <SubTypeSelector
        blockPath={`${blockPath}.subtype`}
        subtypes={subtypes}
        getLabel={(subtype) => tComponents(`FormEditor.user.${subtype}BlockLabel`)}
        defaultValue={subtypes[0]}
      />

      <p> {tComponents('FormEditor.user.Description')}</p>
      <Accordion>
        <AccordionSummary>{tComponents('FormEditor.FieldSettings')}</AccordionSummary>
        <AccordionDetails>
          <>
            <LabelAndRequiredInput
              path={`${blockPath}.firstname`}
              label={tComponents('FormEditor.user.FirstnameLabel')}
              hideRequiredToggle
            />
            <LabelAndRequiredInput
              path={`${blockPath}.lastname`}
              label={tComponents('FormEditor.user.LastnameLabel')}
              hideRequiredToggle
            />
            <LabelAndRequiredInput
              path={`${blockPath}.email`}
              label={tComponents('FormEditor.user.EmailLabel')}
              hideRequiredToggle>
              <CheckboxInput path={`${blockPath}.confirmEmail`} label={tComponents('FormEditor.user.ConfirmEmail')} />
            </LabelAndRequiredInput>
            {block.confirmEmail && (
              <LabelAndRequiredInput
                path={`${blockPath}.emailConfirm`}
                label={tComponents('FormEditor.user.EmailConfirmLabel')}
                hideRequiredToggle
              />
            )}
            {(block.subtype === 'complete' || block.subtype === 'simple') && (
              <LabelAndRequiredInput path={`${blockPath}.phone`} label={tComponents('FormEditor.user.PhoneLabel')} />
            )}
            {block.subtype === 'complete' && (
              <>
                <LabelAndRequiredInput
                  path={`${blockPath}.address`}
                  label={tComponents('FormEditor.Address.AddressLabel')}>
                  <CheckboxInput
                    path={`${blockPath}.state.enabled`}
                    label={tComponents('FormEditor.user.StateEnabled')}
                  />
                  <CheckboxInput
                    path={`${blockPath}.countryCode.enabled`}
                    label={tComponents('FormEditor.user.CountryCodeEnabled')}
                  />
                  <CheckboxInput
                    path={`${blockPath}.completeAddress.enabled`}
                    label={tComponents('FormEditor.user.CompleteAddressEnabled')}
                  />
                  <CheckboxInput
                    path={`${blockPath}.address2.enabled`}
                    label={tComponents('FormEditor.user.Address2Enabled')}
                  />
                </LabelAndRequiredInput>
                <LabelAndRequiredInput
                  path={`${blockPath}.postalCode`}
                  label={tComponents('FormEditor.Address.PostalCodeLabel')}
                />
                <LabelAndRequiredInput
                  path={`${blockPath}.postalPlace`}
                  label={tComponents('FormEditor.Address.PostalPlaceLabel')}
                />
                {block.state?.enabled && (
                  <LabelAndRequiredInput
                    path={`${blockPath}.state`}
                    label={tComponents('FormEditor.Address.StateLabel')}
                  />
                )}
                {block.countryCode?.enabled && (
                  <LabelAndRequiredInput
                    path={`${blockPath}.countryCode`}
                    label={tComponents('FormEditor.Address.CountryCodeLabel')}
                  />
                )}
              </>
            )}
          </>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const InputBlock = ({ block, blockPath }: BlockProps & { block: FormModuleInput & { type: 'input' } }) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const renderInput = () => {
    switch (block.subtype) {
      case 'text':
      case 'date':
      case 'textarea':
      case 'email':
        return <TextInput variant='filled' path={`${blockPath}.label`} label={tComponents('FormEditor.InputLabel')} />;
      case 'address':
        return (
          <>
            <TextInput
              variant='filled'
              path={`${blockPath}.address.label`}
              label={tComponents('FormEditor.Address.AddressLabel')}
            />
            <TextInput
              variant='filled'
              path={`${blockPath}.postalCode.label`}
              label={tComponents('FormEditor.Address.PostalCodeLabel')}
            />
            <TextInput
              variant='filled'
              path={`${blockPath}.postalPlace.label`}
              label={tComponents('FormEditor.Address.PostalPlaceLabel')}
            />
          </>
        );
      case 'file':
        return (
          <>
            <TextInput variant='filled' path={`${blockPath}.label`} label={tComponents('FormEditor.InputLabel')} />
            <TextInput
              variant='filled'
              path={`${blockPath}.buttonLabel`}
              label={tComponents('FormEditor.ButtonLabel')}
            />
            <CheckboxInput
              path={`${blockPath}.previewImage`}
              label={tComponents('FormEditor.input.ShowImagePreview')}
              defaultValue
            />
          </>
        );
      case 'name':
        return (
          <>
            <TextInput
              variant='filled'
              path={`${blockPath}.firstname.label`}
              label={tComponents('FormEditor.input.FirstNameLabel')}
            />
            <TextInput
              variant='filled'
              path={`${blockPath}.lastname.label`}
              label={tComponents('FormEditor.input.LastNameLabel')}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  const subtypes: (typeof block)['subtype'][] = ['text', 'textarea', 'email', 'name', 'address', 'date', 'file'];

  return (
    <>
      <SubTypeSelector
        blockPath={`${blockPath}.subtype`}
        subtypes={subtypes}
        defaultValue='text'
        getLabel={(subtype: string) => tComponents(`FormEditor.input.${subtype}BlockLabel`)}
      />
      {renderInput()}
      <CheckboxInput path={`${blockPath}.required`} label={tCommon('required')} />
    </>
  );
};

const OptionsInputBlock = ({ block, blockPath }: BlockProps & { block: OptionsFormBlock }) => {
  const subtypes: (typeof block)['subtype'][] = ['radio', 'checkbox'];
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  const {
    field: { value: defaultRadioValue, onChange: setDefaultRadioValue },
  } = useController({
    name: `${blockPath}.value`,
  });

  const { fields, append, remove } = useFieldArray({
    name: `${blockPath}.options`,
  });

  return (
    <>
      <SubTypeSelector
        blockPath={`${blockPath}.subtype`}
        subtypes={subtypes}
        defaultValue='radio'
        getLabel={(subtype: string) => tComponents(`FormEditor.options.${subtype}BlockLabel`)}
      />
      <TextInput variant='filled' path={`${blockPath}.label`} label={tCommon('inputLabel')} />
      {fields.map((field, i) => (
        <Box key={field.id} mt={3} display='flex' gap={4}>
          <TextInput
            variant='filled'
            path={`${blockPath}.options.${i}.label`}
            label={tComponents('FormEditor.options.OptionN', { n: i + 1 })}
          />
          <Box display='flex' gap={1}>
            {block.subtype === 'radio' && (
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!defaultRadioValue && defaultRadioValue === block.options[i]?.label}
                      onChange={() => {
                        const isSelected = defaultRadioValue === block.options[i]?.label;
                        setDefaultRadioValue(!isSelected ? block.options[i]?.label : undefined);
                      }}
                    />
                  }
                  label={tComponents('FormEditor.options.PreSelected')}
                />
              </FormControl>
            )}
            {block.subtype === 'checkbox' && (
              <Box>
                <CheckboxInput
                  path={`${blockPath}.options.${i}.value`}
                  label={tComponents('FormEditor.options.PreSelected')}
                />
              </Box>
            )}
            <IconButton onClick={() => remove(i)}>
              <MaterialSymbol name='delete' fill />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Button
        sx={{ marginTop: '20px' }}
        variant='outlined'
        startIcon={<MaterialSymbol name='add' />}
        onClick={() => append({})}>
        {tComponents('FormEditor.options.AddAlternative')}
      </Button>
    </>
  );
};

// eslint-disable-next-line react/no-unused-prop-types
const YesNoBlock = ({ blockPath }: BlockProps & { block: YesNoFormBlock }) => {
  const { t: tCommon } = useTranslation('common');
  return (
    <>
      <TextInput variant='filled' path={`${blockPath}.label`} label={tCommon('inputLabel')} defaultValue='' />
      <CheckboxInput path={`${blockPath}.required`} label={tCommon('required')} />
    </>
  );
};

// eslint is clearly having a bad day. This is not unused.
type BlockProps = {
  blockPath: string;
  // eslint-disable-next-line react/no-unused-prop-types
  block: FormModuleInput;
  // eslint-disable-next-line react/no-unused-prop-types
  onDelete?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onMoveUp?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onMoveDown?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  hideToggleHidden?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  expanded: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  onChangeAccordion: (event: React.SyntheticEvent, expanded: boolean) => void;
};

const Block = ({ block, onDelete, hideToggleHidden, ...rest }: BlockProps) => {
  const renderDetails = () => {
    switch (block.type) {
      case 'input':
        return <InputBlock block={block} {...rest} />;
      case 'options':
        return <OptionsInputBlock block={block} {...rest} />;
      case 'user':
        return <UserBlock block={block} {...rest} />;
      case 'button':
        return <ButtonBlock block={block} {...rest} />;
      case 'content':
        return <ContentBlock block={block} {...rest} />;
      case 'checkbox':
        return <YesNoBlock block={block} {...rest} />;
      default:
        // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
        const _exhaustiveCheck: never = block;
        return <></>;
    }
  };

  const {
    field: { value: hiddenValue, onChange: onChangeHidden },
  } = useController({
    name: `${rest.blockPath}.hidden`,
  });
  const { t: tComponents, i18n } = useTranslation('components');
  const labelI18nKey = `FormEditor.${block.type}.${
    block.type === 'button' && !block.subtype ? 'default' : block.subtype
  }BlockLabel`;

  return (
    <BlockAccordion expanded={rest.expanded} onChange={rest.onChangeAccordion}>
      <BlockAccordionSummary
        expanded={rest.expanded}
        onDelete={onDelete}
        hidden={hiddenValue || false}
        onToggleHidden={!hideToggleHidden ? onChangeHidden : undefined}
        onMoveUp={rest.onMoveUp}
        onMoveDown={rest.onMoveDown}
        hideMoveButtons={hideToggleHidden}>
        {getBlockLabel({
          ...block,
          type: tComponents(`FormEditor.BlockType.${block.type}`),
          title: block.type === 'content' ? block.title ?? '' : undefined,
          subtype:
            block.subtype != null && i18n.exists(labelI18nKey, { ns: 'components' }) && tComponents(labelI18nKey),
        })}
      </BlockAccordionSummary>
      <AccordionDetails>{renderDetails()}</AccordionDetails>
    </BlockAccordion>
  );
};

const Section = ({
  sectionIndex,
  section,
  sectionPath,
  onMoveUp: onMoveUpSection,
  onMoveDown: onMoveDownSection,
  onDelete: onDeleteSection,
}: {
  sectionIndex: number;
  section?: FormSection;
  sectionPath: string;
  onDelete?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}) => {
  const { t: tComponents } = useTranslation('components');
  const title =
    section?.type === 'receipt' ? tComponents('FormEditor.SectionReceipt') : tComponents('FormEditor.SectionInputs');
  const { fields, append, remove, move } = useFieldArray({
    name: `${sectionPath}.inputs`,
  });
  const {
    field: { value: hiddenSection, onChange: onToggleHiddenSection },
  } = useController({
    name: `${sectionPath}.hidden`,
  });

  const { sectionIndexOpen, setSectionIndexOpen, setBlockIndexOpen, blockIndexOpen } =
    useM24FormEditorAccordionContext();

  const types = [
    { type: 'input', defaultValue: { label: 'Overskrift/label', subtype: 'text' } },
    { type: 'options', defaultValue: { label: 'Overskrift/label', subtype: 'radio' } },
    { type: 'user', defaultValue: { subtype: 'minimum' } },
    { type: 'button', defaultValue: { label: '', subtype: '' } },
    { type: 'content', defaultValue: {} },
    { type: 'checkbox', defaultValue: { label: '' } },
  ];

  const rtypes = [{ type: 'button', defaultValue: { label: '', subtype: '' } }];

  const onMoveBlockUp = (index: number) => {
    move(index, index - 1);
  };
  const onMoveBlockDown = (index: number) => {
    move(index, index + 1);
  };
  return (
    <Accordion
      expanded={sectionIndexOpen != null && sectionIndexOpen === sectionIndex}
      onChange={(event, expanded) => {
        setSectionIndexOpen(expanded ? sectionIndex : undefined);
      }}
      sx={{
        width: '100%',
        border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
        '&::before, &::after': {
          display: 'none',
        },
      }}
      elevation={0}>
      <BlockAccordionSummary
        hidden={hiddenSection}
        expanded={sectionIndexOpen != null && sectionIndexOpen === sectionIndex}
        onToggleHidden={!section?.type ? onToggleHiddenSection : undefined}
        onDelete={!section?.type ? onDeleteSection : undefined}
        onMoveUp={onMoveUpSection}
        onMoveDown={onMoveDownSection}
        hideMoveButtons={section?.type === 'receipt'}>
        <Typography fontSize={16} fontWeight={500}>
          {title}
        </Typography>
      </BlockAccordionSummary>
      <AccordionDetails>
        {fields.map((field, i) => {
          const block = section?.inputs?.[i];
          if (!block) {
            return <React.Fragment key={field.id} />;
          }
          return (
            <React.Fragment key={field.id}>
              <Block
                expanded={blockIndexOpen === i && sectionIndexOpen === sectionIndex}
                onChangeAccordion={(event, expanded) => {
                  setBlockIndexOpen(expanded ? i : undefined);
                }}
                block={block}
                onDelete={!section?.type ? () => remove(i) : undefined}
                blockPath={`${sectionPath}.inputs.${i}`}
                onMoveUp={i > 0 ? () => onMoveBlockUp(i) : undefined}
                onMoveDown={i < fields.length - 1 ? () => onMoveBlockDown(i) : undefined}
                hideToggleHidden={section?.type === 'receipt'}
              />
            </React.Fragment>
          );
        })}
        <Box display='flex' flexWrap='wrap'>
          {section?.type === 'receipt'
            ? rtypes.map((type) => (
                <Button
                  sx={{ marginRight: '10px', marginTop: '10px', border: '2px solid' }}
                  key={type.type}
                  onClick={() => append({ ...type.defaultValue, type: type.type })}>
                  {tComponents('FormEditor.AddBlockType', {
                    blockType: tComponents(`FormEditor.BlockType.${type.type}`),
                  })}
                </Button>
              ))
            : types.map((type) => (
                <Button
                  sx={{ marginRight: '10px', marginTop: '10px', border: '2px solid' }}
                  key={type.type}
                  onClick={() => append({ ...type.defaultValue, type: type.type })}>
                  {tComponents('FormEditor.AddBlockType', {
                    blockType: tComponents(`FormEditor.BlockType.${type.type}`),
                  })}
                </Button>
              ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const PageLinkSection: FC<{ form: FormModule }> = ({ form }) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  return (
    <Accordion
      sx={{
        width: '100%',
        borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
        '&::before, &::after': {
          display: 'none',
        },
        backgroundColor: Styles.Colors.LIGHT_GREY,
      }}>
      <AccordionSummary>
        {tComponents('FormEditor.InUseByPages')} ({form.used_in_pages.length || 0})
      </AccordionSummary>
      <AccordionDetails>
        {form.used_in_pages?.map((page) => (
          <Link to={`/editor/${page.id}`} key={page.id}>
            <Box display='flex' gap={2} alignItems='center' marginTop={1}>
              <Typography>{page.title}</Typography>
              <Box display='flex' alignItems='center' gap={1}>
                <PageStatusCircle status={page.status} />
                <Typography variant='caption' color='textSecondary'>
                  {tCommon(`Status.${page.status}`)}
                </Typography>
              </Box>
            </Box>
          </Link>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const SettingsSection = () => {
  const {
    fields: emailReceivers,
    append: addEmailReceiver,
    remove: removeEmailReceiver,
  } = useFieldArray({
    name: `content.email_receivers`,
  });
  const store = useStore();
  const languages = store.state.selectedSite?.site_contents ?? [];
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { settingsOpen, setSettingsOpen } = useM24FormEditorAccordionContext();

  return (
    <Accordion
      expanded={settingsOpen}
      onChange={(_event, expanded) => {
        setSettingsOpen(expanded);
      }}
      sx={{
        width: '100%',
        borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
        '&::before, &::after': {
          display: 'none',
        },
        backgroundColor: Styles.Colors.LIGHT_GREY,
      }}
      elevation={2}
      disableGutters>
      <AccordionSummary
        sx={{
          flexGrow: 1,
          flexDirection: 'row-reverse',
          pr: 2,
          width: '100%',
        }}
        expandIcon={
          <MaterialSymbol
            name='chevron_right'
            color='secondary'
            sx={settingsOpen ? { transform: 'rotate(-90deg)' } : undefined}
          />
        }>
        <Typography fontSize={16} fontWeight={500}>
          {tCommon('settings')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextInput variant='filled' path='title' label={tCommon('title')} defaultValue='' />
        <TextInput
          variant='filled'
          path='content.description'
          label={tCommon('internalDescription')}
          multiline
          defaultValue=''
        />
        <Box mt={1} mb={1}>
          <SelectInput
            options={[1, 5, 10, 15, 20, 25]}
            getOptionKey={(opt) => `${opt}`}
            getOptionLabel={(opt) => `${opt} MB${opt === 10 ? `(${tCommon('default')})` : ''}`}
            defaultValue={10}
            path='content.attachmentsSizeLimit'
            label={tComponents('FormEditor.AttachmentsSizeLimit')}
          />
        </Box>
        <Box mt={1} mb={1}>
          <CheckboxInput path='content.send_email' label={tComponents('FormEditor.SendEmailOnSubmissions')} />
        </Box>

        {emailReceivers.map((field, i) => (
          <Box mt={1} key={field.id}>
            <Container gap={1}>
              <TextInput
                variant='filled'
                path={`content.email_receivers.${i}.email`}
                label={tComponents('FormEditor.EmailReceiver')}
              />
              <DeleteButton onConfirm={() => removeEmailReceiver(i)} />
            </Container>
          </Box>
        ))}
        <Box mt={1} mb={1}>
          <Button
            variant='outlined'
            onClick={() =>
              addEmailReceiver({
                name: '',
                email: '',
              })
            }>
            Legg til epost-mottaker
          </Button>
        </Box>
        <hr />
        <TextInput variant='filled' path='content.formNotValid' label={tComponents('FormEditor.FormInvalidLabel')} />
        <Box mt={1} mb={1}>
          <SelectInput
            options={languages.map((lang) => lang.locale)}
            getOptionKey={(opt) => opt}
            getOptionLabel={(opt) => tCommon(`Languages.${opt}`)}
            path='locale'
            label={tCommon('language')}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const FormEditorSectionContainer = () => {
  const formModel = useEditorModel<FormModule>();
  const { fields, insert, remove, move } = useFieldArray({
    name: 'content.sections',
  });
  const { t: tComponents } = useTranslation('components');
  return (
    <Container
      sx={{
        position: 'relative',
        backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
        overflow: 'auto',
      }}
      column
      gap={0}
      p={0}
      top
      fullHeight
      fullWidth>
      <SettingsSection />

      {fields.map((field, i) => {
        const section = formModel.content?.sections?.[i];
        const canMoveUp = i > 0 && section?.type !== 'receipt';
        const canMoveDown = i < fields.length - 2 && section?.type !== 'receipt';
        return (
          <React.Fragment key={field.id}>
            <Section
              sectionIndex={i}
              section={section}
              sectionPath={`content.sections.${i}`}
              onDelete={section?.type !== 'receipt' ? () => remove(i) : undefined}
              onMoveUp={canMoveUp ? () => move(i, i - 1) : undefined}
              onMoveDown={canMoveDown ? () => move(i, i + 1) : undefined}
            />
          </React.Fragment>
        );
      })}
      <Button
        sx={{ marginTop: '16px', marginBottom: '16px', border: '2px solid' }}
        onClick={() =>
          insert(fields.length - 1, {
            inputs: [],
          })
        }>
        {tComponents('FormEditor.AddSection')}
      </Button>

      {!!formModel?.used_in_pages?.length && <PageLinkSection form={formModel} />}
    </Container>
  );
};

const FormEditorLayout = ({ formId, isCreatingNew }: { formId?: number; isCreatingNew?: boolean }) => {
  const [form, setForm] = useState<FormModule | undefined>();
  const store = useStore();
  const siteId = store?.state?.selectedSite?.id || 0;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { reset, setValue, handleSubmit } = useFormContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t: tCommon } = useTranslation('common');

  const [sectionIndexOpen, setSectionIndexOpen] = useState<number | undefined>(undefined);
  const [blockIndexOpen, setBlockIndexOpen] = useState<number | undefined>(undefined);
  const [settingsIsOpen, setSettingsIsOpen] = useState<boolean | undefined>(!!isCreatingNew);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const accordionContextValue = useMemo<AccordionContextValue>(
    () => ({
      settingsOpen: settingsIsOpen,
      setSettingsOpen: (b?: boolean) => {
        setSettingsIsOpen(b);
        setSectionIndexOpen(undefined);
        setBlockIndexOpen(undefined);
      },
      sectionIndexOpen,
      blockIndexOpen,
      setSectionIndexOpen: (i?: number) => {
        setSectionIndexOpen(i);
        setBlockIndexOpen(undefined);
        setSettingsIsOpen(false);
      },
      setBlockIndexOpen,
    }),
    [blockIndexOpen, sectionIndexOpen, settingsIsOpen],
  );

  useEffect(() => {
    if (isCreatingNew) {
      const initialForm: FormModule = {
        site_id: siteId,
        type: 'form',
        status: Status.DRAFT,
        locale: store.state.selectedSiteLanguage || 'no',
        title: '',
        content: {
          sections: [
            {
              inputs: [],
            },
            {
              type: 'receipt',
              inputs: [
                {
                  type: 'content',
                },
              ],
            },
          ],
        },
        used_in_pages: [],
      };
      setForm(initialForm);
      reset(initialForm);
      return;
    }
    if (!formId) {
      return;
    }

    setIsLoading(true);
    const ctx = Api.getFormModule(formId);
    ctx
      .fetchDirect(null)
      .then((res) => {
        if (res) {
          reset(res);
          setForm(res);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      ctx.abort();
    };
  }, [formId, isCreatingNew, reset, siteId, store.state.selectedSiteLanguage]);

  const submitHandler = handleSubmit(async (model) => {
    try {
      const response = await Api.saveFormModule(model).fetchDirect(null);
      if (isCreatingNew && response) {
        navigate(`/formEditor/${response.id}`);
      } else if (response) {
        setForm(response);
      }
      enqueueSnackbar(tCommon('saved'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
    }
  });

  const onSave = async (saveType: SaveType) => {
    switch (saveType) {
      case SaveType.SAVE:
        break;
      case SaveType.SAVE_AND_PUBLISH:
        setValue('status', Status.PUBLISHED);
        break;
      case SaveType.SAVE_AND_UNPUBLISH:
        setValue('status', Status.DRAFT);
        break;
    }
    await submitHandler();
  };

  return (
    <AccordionContext.Provider value={accordionContextValue}>
      <EditorLayout
        header={
          <M24FormEditorHeader
            form={form}
            isLoading={isLoading}
            onSave={onSave}
            menuOpen={menuOpen}
            onToggleMenuOpen={() => setMenuOpen((o) => !o)}
          />
        }
        preview={<M24FormEditorPreviewWrapper />}>
        {isLoading && <div>{tCommon('loading')}</div>}
        {!isLoading && form && <FormEditorSectionContainer />}
        <Drawer open={menuOpen} onClose={() => setMenuOpen(false)} variant='temporary'>
          <Container column top left fullWidth fullHeight p={1}>
            <Container right fullWidth gap={0}>
              <IconButton color='secondary' onClick={() => setMenuOpen(false)}>
                <MaterialSymbol name='menu_open' />
              </IconButton>
            </Container>

            <MainMenu open={menuOpen} toggleMenuOpen={() => setMenuOpen(!menuOpen)} />
          </Container>
        </Drawer>
      </EditorLayout>
    </AccordionContext.Provider>
  );
};

const M24FormEditor = () => {
  const params = useParams();
  const formId = params.formId ? parseInt(params.formId, 10) : 0;
  const isCreatingNew = Number.isNaN(parseInt(params.formId || '', 10)) || formId <= 0;

  return (
    <EditorEventManager>
      <Form<FormModule>>
        <FormEditorLayout formId={formId} isCreatingNew={isCreatingNew} />
      </Form>
    </EditorEventManager>
  );
};

export default M24FormEditor;
