/* istanbul ignore file */
import { Page } from '@/declarations/models/Page';
import { BaseBlock } from './BaseBlock';
import { BlockType } from '../BlockType';
import { SubCategory } from '../../Category';

export enum EventsLayout {
  TEXT_ON_IMAGE = 'img',
  IMAGE_TOP = 'img-top',
}

export enum EventsViewType {
  GRID = 'grid',
  LIST = 'list',
}

export interface EventPageItem {
  id: number;
  visible?: boolean;
  local?: {
    description?: string;
    skin?: string;
    title?: string;
    highlight?: boolean;
  };
}

export interface EventsBlock extends BaseBlock {
  layout?: EventsLayout;
  view?: EventsViewType;
  disableExpandedDates?: boolean;
  displayCategory?: boolean;
  displayDescription?: boolean;
  displayLocation?: boolean;
  displayMuseum?: boolean;
  showAllLinkPage?: Page | null;
  showAllLinkType?: 'button' | 'text';
  gridSize?: string;
  gridMobileBehaviour?: string;
}

export const ShowForPeriodType = ['previous', 'today', '1m', '3m', '6m', '12m', 'specify'] as const;
export interface EventsAutoBlock extends EventsBlock {
  type: BlockType.EVENTS_AUTO;
  subCategories: Array<SubCategory>;
  siteIds?: string;
  locationIds?: string;
  userMuseumFilter?: boolean;
  userLocationFilter?: boolean;
  userDateFilter?: boolean;
  userTodayFilter?: boolean;
  userCategoryFilter?: boolean;
  userTargetGroupFilter?: boolean;
  showForPeriodType?: (typeof ShowForPeriodType)[number];
  showForPeriodStart?: string | null;
  showForPeriodEnd?: string | null;
  maxSearchResults?: number | null;
}

export interface EventsManualBlock extends EventsBlock {
  type: BlockType.EVENTS_MANUAL;
  items: Array<EventPageItem>;
}
