import React from 'react';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { TextBlock } from '../../../declarations/models/blocks/TextBlock';
import { Page } from '../../../declarations/models/Page';
import { useEditorModel } from '../../lib/components/EditorDataProvider';
import ImagePreview from '../../../components/ImagePreview';
import { BlockModuleType } from '../../../declarations/models/BlockModuleType';
import LinksPreview from './LinksPreview';
import { isM24MediaModel } from '../../../utils/typeChecks';

export const TextBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as TextBlock;
  const model: Page = useEditorModel<Page>();
  const children = model.children as Array<Page>;

  const linkItems = currentBlock?.listchildren
    ? (currentBlock?.items as Array<Page>).concat(children)
    : currentBlock?.items;

  const media = currentBlock?.mediaobject;
  const caption = media?.local?.caption || (isM24MediaModel(media) && media.content?.caption);

  return (
    <div className='article__grid' data-layout={currentBlock?.layout || 'auto'}>
      {currentBlock?.module__type !== BlockModuleType.MODULE && (
        <div className={`article__content layout--${currentBlock?.layout || 'auto'}`}>
          {currentBlock?.mediaobject && (
            <figure
              data-fullscreen='false'
              className={`media article__media module--media media-format--auto layout--${
                currentBlock?.layout || 'auto'
              }`}>
              <ImagePreview mediaObject={currentBlock?.mediaobject}>
                <div className='module__content'>
                  <div className='module__body'>
                    <figcaption className='media__caption'>
                      {caption || ''}

                      <span className='media__credit  caption--credit'>
                        {currentBlock?.mediaobject?.content?.credits?.map(
                          (credit) =>
                            credit?.name && (
                              <span className={credit?.type} key={credit?.name}>
                                {credit?.name}
                              </span>
                            ),
                        )}
                      </span>
                    </figcaption>
                  </div>
                </div>
              </ImagePreview>
            </figure>
          )}
          <div className='article__bodytext'>
            {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
            <div className='module__body'>
              <div
                dangerouslySetInnerHTML={{ __html: currentBlock?.bodytext || '' }} // eslint-disable-line react/no-danger
              />

              {currentBlock?.itemsTitle && <strong className='article__links_title'>{currentBlock?.itemsTitle}</strong>}
              <LinksPreview linkItems={linkItems} linkStyle={currentBlock?.linkstyle} />
            </div>
          </div>
        </div>
      )}
      {currentBlock?.module__type === BlockModuleType.MODULE && (
        <div className={`module module--card module--content layout--${currentBlock?.layout || 'auto'} `}>
          <div className={`module__grid skin-contentbox ${currentBlock?.skin || 'skin'}`}>
            {currentBlock?.state === 'semi' ? (
              <div className='preview__blockmessage'>Tekstboks vil være delvis åpen i portal</div>
            ) : null}
            {currentBlock?.state === 'collapsed' ? (
              <div className='preview__blockmessage'>Tekstboks vil være lukket i portal</div>
            ) : null}
            {currentBlock?.mediaobject && <ImagePreview mediaObject={currentBlock?.mediaobject} />}
            <div className='module__content'>
              <div className='module__head'>
                {currentBlock?.icon && <i className={`module__icon i-icon-${currentBlock?.icon}`} />}
                {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
              </div>
              <div className='module__body'>
                {/* eslint-disable-next-line react/no-danger */}
                <p className='modulebodytext' dangerouslySetInnerHTML={{ __html: currentBlock?.bodytext || '' }} />
                {currentBlock?.itemsTitle && <strong>{currentBlock?.itemsTitle}</strong>}
                <LinksPreview linkItems={linkItems} linkStyle={currentBlock?.linkstyle} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextBlockPreview;
