import React, { FC } from 'react';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { Icon, Box } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import HeaderThumbnail from '../MediaBlock/MediaBlockHeader/HeaderThumbnail';
import HeaderTitle from '../MediaBlock/MediaBlockHeader/HeaderTitle';
import Container from '../../../../components/Container';
import { ChildrenProp } from '../../../../declarations/ChildrenProp';
import { Card } from '../../../../declarations/models/Card';
import { Person } from '../../../../declarations/models/Person';

export interface CardModuleBlockHeaderProps {
  open: boolean;
  item: Card | Person;
}

export const CardModuleBlockItemHeader: FC<CardModuleBlockHeaderProps & ChildrenProp> = ({
  children,
  open = true,
  item,
}) => {
  return (
    <Container left fullWidth>
      {item.content?.mediaobject && <HeaderThumbnail item={item.content?.mediaobject} hidden={open} alt='altText' />}
      <Container column left fullWidth>
        <Container gap={0} left fullWidth>
          {item.page?.title && (
            <Box sx={{ marginLeft: '10px' }}>
              <PageStatusCircle status={item.page.status} />
            </Box>
          )}

          {!item.page_id && (
            <Icon sx={{ marginLeft: '0px', width: '40px' }}>
              <MaterialSymbol name='link' />
            </Icon>
          )}

          <HeaderTitle title={item?.title || ''} />
        </Container>
        <Container px={1}>{children}</Container>
      </Container>
    </Container>
  );
};

export default CardModuleBlockItemHeader;
