// TODO: split into separate types for image/audio/video etc.?!
export enum MimeTypeDMS {
  PDF = 'application/pdf',
  WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  WORD_OLD = 'application/msword',

  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  // TODO: implement support
  // SVG = 'image/svg+xml',

  MPEG_VIDEO = 'video/mpeg',
  MP4_VIDEO = 'video/mp4',
  OGG_VIDEO = 'video/ogg',
  WEBM = 'video/webm',
  WMV = 'video/x-ms-wmv',
  FLV = 'video/x-flv',

  MP4_AUDIO = 'audio/mp4',
  MPEG_AUDIO = 'audio/mpeg',
  OGG_AUDIO = 'audio/ogg',
  VORBIS = 'audio/vorbis',
}
