import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useStore } from '@/components/store/Store';
import { Page } from '../../../declarations/models/Page';
import Container from '../../../components/Container';
import ActionMenu from '../../../components/ActionMenu';
import { useContentView } from '../ContentViewContext';
import DeleteButton from '../../../components/DeleteButton';
import { Status } from '../../../declarations/models/Status';

export interface PageTreeActionMenuProps {
  page: Page;
  onClose?: () => void;
}

export const PageTreeActionMenu: FC<PageTreeActionMenuProps> = ({ page, onClose }) => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { state } = useStore();
  const { selectionState, deletePage, changePageStatus, portalUrl } = useContentView();
  const { hasChildren } = selectionState;
  const isFrontpage = page.id === state.selectedSiteDefaultPageId;

  const key = String(page?.id || 0);
  const isExpandable = hasChildren(key);

  const createKey = (...parts: Array<string>): string => ['page', key, ...parts].join('-');

  const publishMenuItem = () => {
    if (page.status !== Status.PUBLISHED) {
      return (
        <MenuItem key={createKey('publish', 'button')} onClick={() => changePageStatus(page, Status.PUBLISHED)}>
          <ListItemIcon>
            <MaterialSymbol name='publish' />
          </ListItemIcon>
          <ListItemText>{tComponents('PageTreeActionMenu.MenuItems.Publish')}</ListItemText>
        </MenuItem>
      );
    }
  };
  const unpublishMenuItem = () => {
    if (!isFrontpage && page.status !== Status.DRAFT) {
      return (
        <MenuItem key={createKey('unpublish', 'button')} onClick={() => changePageStatus(page, Status.DRAFT)}>
          <ListItemIcon>
            <MaterialSymbol name='unpublished' />
          </ListItemIcon>
          <ListItemText>{tComponents('PageTreeActionMenu.MenuItems.Unpublish')}</ListItemText>
        </MenuItem>
      );
    }
  };
  const archiveMenuItem = () => {
    if (!isFrontpage && page.status !== Status.ARCHIVED) {
      return (
        <MenuItem key={createKey('archive', 'button')} onClick={() => changePageStatus(page, Status.ARCHIVED)}>
          <ListItemIcon>
            <MaterialSymbol name='archive' />
          </ListItemIcon>
          <ListItemText>{tComponents('PageTreeActionMenu.MenuItems.Archive')}</ListItemText>
        </MenuItem>
      );
    }
  };
  const deleteMenuItem = () => {
    if (!isFrontpage && page.status !== Status.PUBLISHED) {
      return (
        <DeleteButton
          key={createKey('delete', 'button')}
          onConfirm={() => deletePage(page)}
          component='MenuItem'
          disabled={!page?.id || isExpandable}
        />
      );
    }
  };

  return (
    <Container right>
      <ActionMenu
        id='page-tree-actions'
        ariaLabel={tAria('components.PageTreeActionMenu.MenuDescription', { pageName: page.title })}
        onClose={onClose}>
        {[
          <MenuItem
            key={createKey('portal', 'link')}
            component={Link}
            href={`${portalUrl}${page.path ? `/${page.path}` : ''}`}
            target='_blank'>
            <ListItemIcon>
              <MaterialSymbol name='visibility' fill />
            </ListItemIcon>
            <ListItemText>{tComponents('PageTreeActionMenu.MenuItems.ShowInPortal')}</ListItemText>
          </MenuItem>,
          publishMenuItem(),
          unpublishMenuItem(),
          archiveMenuItem(),
          deleteMenuItem(),
        ]}
      </ActionMenu>
    </Container>
  );
};

export default PageTreeActionMenu;
