import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SiteUsersSettingsList } from '@/views/SettingsView/SiteUsersSettings/SiteUsersSettingsList';
import { User } from '@/declarations/models/User';
import { useStore } from '@/components/store/Store';
import { useApi } from '@/hooks/useApi';
import { Api } from '@/services/Api';
import SettingsViewLayout from '../../../components/SettingsViewLayout';
import Container from '../../../components/Container';
import Loader from '../../../components/Loader';

export const SiteUsersSettings: FC = () => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { state } = useStore();
  const { currentUser } = state;
  const selectedSiteId = state.selectedSite?.id ?? 0;
  const [userList, isLoadingUsers, reloadUsers] = useApi<{ users: User[] }, User[]>(() =>
    Api.getSiteUserRoles(selectedSiteId),
  );

  useEffect(() => {
    reloadUsers();
  }, [reloadUsers, selectedSiteId]);

  return (
    <SettingsViewLayout
      title={tComponents('MainMenu.settings.users')}
      saveButtonInHeader={false}
      saveButtonInTop={false}>
      <>
        {isLoadingUsers ? (
          <Container fullWidth fullHeight>
            <Loader size={25} />
          </Container>
        ) : (
          <Container fullHeight top fullWidth column left>
            <Container fullHeight top fullWidth>
              <Container fullWidth top left column gap={2}>
                <Button
                  variant='contained'
                  color='secondary'
                  startIcon={<MaterialSymbol name='save' />}
                  onClick={() => {
                    // TODO
                  }}
                  style={{ display: currentUser?.user?.is_super_admin ? 'inline-flex' : 'none' }}>
                  {tCommon('save')}
                </Button>
                <SiteUsersSettingsList users={userList?.map((user) => ({ ...user, is_noAccess: false })) ?? []} />
              </Container>
            </Container>
          </Container>
        )}
      </>
    </SettingsViewLayout>
  );
};

export default SiteUsersSettings;
