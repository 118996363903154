import React, { FC, useEffect, useState } from 'react';
import { formatAPITimestamp } from '../../../../utils/dates';
import {
  PageListAutoBlock,
  PageListItem,
  PageListManualBlock,
  PageListViewType,
} from '../../../../declarations/models/blocks/PageListBlocks';
import { Api } from '../../../../services/Api';
import { Page } from '../../../../declarations/models/Page';
import { calculateObjectPosition } from '../../../../components/Image';

interface PageListBlockPreviewProps {
  item?: PageListItem;
  autoPage?: Page;
  block: PageListAutoBlock | PageListManualBlock;
}

export const PageListBlockPreviewItem: FC<PageListBlockPreviewProps> = ({ item, autoPage, block }) => {
  const [page, setPage] = useState<Page>();

  useEffect(() => {
    if (item?.id) {
      const ctx = Api.getPageSimple(item.id);
      ctx
        .fetchDirect(null)
        .then((p) => !!p && setPage(p))
        .finally(ctx.abort);
    } else if (autoPage) setPage(autoPage);
  }, [item?.id, autoPage]);

  if (block.view === PageListViewType.GRID)
    return (
      <div data-module='frontpage__module' data-size={item?.local?.highlight || block?.highlightAll ? 'm' : 's-m'}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className={`module--link status--${page?.status}`}>
          <div
            className={`module
                            module--banner
                            module--pagebanner
                            module--${item?.local?.highlight ? 'm' : 's-m'}
                            layout--${block?.layout || 'img'}
                            ${item?.local?.skin ? item?.local?.skin : block?.skin || 'skin'}
                            ${page?.image?.url || page?.image_src ? 'has-media' : 'no-media'}
                            skin-pagebanner 
                            skin-autocategory`}>
            <div className='module__grid'>
              {block?.displayCategory && (
                <div className='module__tags'>
                  {page?.main_category && (
                    <div key={page.main_category} className='module__tag'>
                      {page.main_category}
                    </div>
                  )}
                  {page?.sub_categories?.map((c) => (
                    <div key={c} className='module__tag'>
                      {c}
                    </div>
                  ))}
                </div>
              )}
              <div className='module__media'>
                {page?.image?.url ? (
                  <img
                    style={{
                      objectPosition: calculateObjectPosition(page?.image_focus_point?.x, page?.image_focus_point?.y),
                    }}
                    src={`${page?.image.url}?&dimension=600x600${
                      page?.image?.mimetype?.includes('png') ? '&mediaType=image/png' : ''
                    }`}
                    alt=''
                  />
                ) : null}
              </div>
              <div className='module__content'>
                <div className='content__layout'>
                  <div className='module__head'>
                    {block?.displayInsertTitle && <div className='banner__insert'>{page?.insert_title}</div>}
                    <span className='banner__title'>
                      <span className='modify--size'>
                        <h3 className='hyphenate module__heading  h-default f-default'>
                          {item?.local?.title || page?.title}
                        </h3>
                      </span>
                    </span>
                    {(block?.displayPublishedDate || block?.displayLocation || block?.displayMuseum) && (
                      <div className='module__time_place'>
                        {block?.displayPublishedDate && (
                          <span>{formatAPITimestamp(page?.published_at, 'date')?.toLocaleString()}</span>
                        )}
                        {block?.displayLocation && <span>{page?.location?.title || ''}</span>}
                        {block?.displayMuseum && <span>{page?.site_name || ''}</span>}
                      </div>
                    )}
                  </div>
                  <div className='module__body'>
                    {block.displayDescription && item?.local?.description ? (
                      <p>{item?.local?.description}</p>
                    ) : (
                      block.displayDescription && <p>{page?.description}</p>
                    )}
                  </div>
                  <div className='module__foot' />
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    );

  return (
    <div key={page?.id} className={`module  module--card status--${page?.status}`}>
      <div className={`module__grid ${item?.local?.skin ? item?.local?.skin : block?.skin || 'skin'}`}>
        <div className='module__media'>
          {page?.image?.url ? (
            <img
              style={{
                objectPosition: calculateObjectPosition(page?.image_focus_point?.x, page?.image_focus_point?.y),
              }}
              src={`${page?.image.url}?&dimension=600x600`}
              alt=''
            />
          ) : null}
          {page?.image_src ? (
            <img
              style={{
                objectPosition: calculateObjectPosition(page?.image_focus_point?.x, page?.image_focus_point?.y),
              }}
              src={`${page?.image_src}?&dimension=600x600`}
              alt=''
            />
          ) : null}
        </div>
        <div className='module__content'>
          <div className='module__head'>
            {block?.displayInsertTitle && <div className='banner__insert'>{page?.insert_title || ''}</div>}
            <h3 className='card-title'>{item?.local?.title || page?.title}</h3>
            {(block?.displayPublishedDate || block?.displayLocation || block?.displayMuseum) && (
              <div className='module__time_place'>
                {block?.displayPublishedDate && (
                  <span>{formatAPITimestamp(page?.published_at, 'date')?.toLocaleString()}</span>
                )}
                {block?.displayLocation && <span>{page?.location?.title || ''}</span>}
                {block?.displayMuseum && <span>{page?.site_name || ''}</span>}
              </div>
            )}
          </div>
          <div className='module__body'>
            <p>{page?.description || item?.local?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageListBlockPreviewItem;
