import Container from '@/components/Container';
import React, { FC, useEffect, useState } from 'react';
import { Page } from '@/declarations/models/Page';
import { useStore } from '@/components/store/Store';
import { Api } from '@/services/Api';
import Loader from '@/components/Loader';
import { calculateObjectPosition } from '@/components/Image';
import { Button } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '@/assets/js/Styles';
import { useTranslation } from 'react-i18next';

export const WelcomeBannerWidget: FC<unknown> = () => {
  const { t: tComponents } = useTranslation('components');
  const [frontpage, setFrontpage] = useState<Page>();
  const [loading, setLoading] = useState(true);
  const {
    state: { selectedSiteDefaultPageId },
  } = useStore();
  useEffect(() => {
    if (selectedSiteDefaultPageId) {
      setLoading(true);
      Api.getPage(selectedSiteDefaultPageId)
        .fetchDirect(undefined)
        .then(setFrontpage)
        .finally(() => setLoading(false));
    }
  }, [selectedSiteDefaultPageId]);

  if (loading) {
    return (
      <Container
        fullWidth
        fullHeight
        top
        sx={{
          minHeight: '300px',
          aspectRatio: '16/9',
        }}>
        <Loader />
      </Container>
    );
  }

  return (
    <Container
      fullWidth
      fullHeight
      top
      left
      sx={{
        minHeight: '300px',
        aspectRatio: '16/9',
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
      }}>
      {!frontpage && (
        <Container fullWidth top left>
          <div>todo: default fallback content?</div>
        </Container>
      )}
      {frontpage && (
        <Container
          fullWidth
          fullHeight
          column
          top
          left
          sx={{
            backgroundImage: frontpage.image_src ? `url(${frontpage.image_src})` : undefined,
            backgroundSize: 'cover',
            backgroundPosition: calculateObjectPosition(frontpage.image?.x, frontpage.image?.y) ?? 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          }}>
          <Container
            column
            left
            spaceBetween
            sx={{
              backgroundColor: 'rgba(0,0,0,0.6)',
              padding: '1rem',
              color: 'white',
              height: '100%',
              width: '40%',
            }}>
            {frontpage.description && <p>{frontpage.description}</p>}
            {!frontpage.description && <i>{tComponents('DashboardView.WelcomeBannerWidget.NoDescription')}</i>}
            <Container left column>
              {/* todo: include some queryparam for editing description/image */}
              <Button
                startIcon={<MaterialSymbol name='edit' />}
                fullWidth
                href={`/editor/${frontpage.id}`}
                variant='contained'
                color='primary'>
                {tComponents('DashboardView.WelcomeBannerWidget.DescriptionLabel')}
              </Button>
              <Button
                startIcon={<MaterialSymbol name='edit' />}
                fullWidth
                href={`/editor/${frontpage.id}`}
                variant='contained'
                color='primary'>
                {tComponents('DashboardView.WelcomeBannerWidget.ImageLabel')}
              </Button>
            </Container>
          </Container>
        </Container>
      )}
    </Container>
  );
};
