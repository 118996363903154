import React, { FC } from 'react';
import { Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import M24PageFinderPageTreeLabel from './M24PageFinderPageTreeLabel';
import { formatAPITimestamp } from '../../../../utils/dates';
import { Page } from '../../../../declarations/models/Page';
import Container from '../../../Container';
import { useM24PageFinderContentView } from '../M24PageFinder';
import Image from '../../../Image';
import { Status } from '../../../../declarations/models/Status';

export interface PageTreeContentProps {
  page: Page;
  diffuse?: boolean;
  selected?: boolean;
}

export const M24PageFinderPageTreeContent: FC<PageTreeContentProps> = ({ page, diffuse, selected }) => {
  const { t } = useTranslation('common');
  const { onSelect } = useM24PageFinderContentView();

  return (
    <Container
      role='link'
      onClick={(e) => {
        e.stopPropagation();
        onSelect(page);
      }}
      sx={{
        opacity: diffuse ? 0.5 : 1,
        height: '48px',
        cursor: 'pointer',
        py: 4,
      }}
      spaceBetween
      fullWidth>
      <Container fullWidth spaceBetween>
        <M24PageFinderPageTreeLabel page={page} />
        <Container right>
          {!!page?.main_category && <Chip color='info' label={t(`MainCategory.${page.main_category}`)} size='small' />}
          {page?.sub_categories?.map((c) => (
            <Chip key={c} variant='outlined' color='info' label={t(`SubCategory.${c}`)} size='small' />
          ))}
        </Container>
      </Container>

      <Container spaceBetween sx={{ minWidth: '320px' }}>
        <Container column left sx={{ minWidth: '220px' }} gap={0}>
          <Container>
            <PageStatusCircle status={page.status} size={18} />
            <Typography variant='caption' color={page.status === Status.PUBLISHED ? 'green' : 'textSecondary'}>
              {t(`Status.${page.status}`)}
            </Typography>
          </Container>
          <Container>
            <Typography variant='caption' color='textSecondary' noWrap>
              {t('lastEdited')}: {formatAPITimestamp(page.updated_at || '', 'date') || '-'}
            </Typography>
          </Container>
        </Container>
        <Container>
          <Image
            sx={{
              overflow: 'hidden',
              objectFit: 'cover',
              height: '40px',
              width: '40px',
              borderRadius: Styles.Dimensions.RADIUS_ROUND_FULL,
              backgroundColor: Styles.Colors.LIGHT_GREY,
              border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
            }}
            src={`${page.image_src}?&dimension=250x250${
              page.image?.mimetype?.includes('png') ? '&mediaType=image/png' : ''
            }`}
          />
        </Container>
      </Container>

      <Container sx={{ minWidth: '24px' }}>{selected && <MaterialSymbol name='check' color='info' />}</Container>
    </Container>
  );
};

export default M24PageFinderPageTreeContent;
