import React, { FC } from 'react';
import { ExternalMediaModel, isDiMuMediaModel } from '../../../../../declarations/models/ExternalMediaModel';
import { BlockItemPathPrefix } from '../../../../../declarations/models/BlockItemPathPrefix';
import Container from '../../../../../components/Container';
import Image from '../../../../../components/Image';
import CaptionInput from '../CaptionInput';
import { ChildrenProp } from '../../../../../declarations/ChildrenProp';

export interface DMContentProps {
  item: ExternalMediaModel;
  pathPrefix: BlockItemPathPrefix;
}

export const DMContent: FC<DMContentProps & ChildrenProp> = ({ children, item, pathPrefix }) => {
  if (!isDiMuMediaModel(item)) {
    console.error('DMContent rendered with an incorrect model. This should never happen.');
    return null;
  }

  const title =
    item.local.title ||
    item.item['artifact.ingress.title'] ||
    item.id ||
    item.dimuId ||
    item.dmsId ||
    item.objectId ||
    '';

  return (
    <Container p={2} column fullWidth>
      <Container gap={2} top spaceBetween fullWidth>
        <Container column sx={{ maxWidth: '200px' }} left fullWidth>
          <Image
            src={item.url}
            title={title}
            alt={title}
            maxWidth='200px'
            focusPoint={{
              x: item.x || item.local?.x || '0',
              y: item.y || item.local?.y || '0',
            }}
          />
        </Container>

        <CaptionInput
          toggleCaptionVisibilityPath={`${pathPrefix}.local.disableCaption`}
          captionPath={`${pathPrefix}.local.caption`}
        />
      </Container>
      <Container fullWidth>{children}</Container>
    </Container>
  );
};

export default DMContent;
