import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '@/assets/js/Styles';
import UploadMediaStage from './UploadMediaStage';
import { M24MediaModel } from '../../../declarations/models/M24MediaModel';
import { toggleItem } from '../../../utils/array';
import EditMediaStage from './EditMediaStage';
import { Api } from '../../../services/Api';
import { EditSelectedMediaProps } from '../EditSelectedMedia';
import { getMediaType } from '../../../utils/MediaUtils';
import Loader from '../../../components/Loader';

interface UploadMediaModalProps extends Omit<EditSelectedMediaProps, 'selectedItems'> {
  open: boolean;
  closeModal: () => void;
}

enum UploadStage {
  UPLOAD = 'upload',
  EDIT = 'edit',
}

export const UploadMediaModal: FC<UploadMediaModalProps> = ({ open, closeModal, siteId, ...rest }) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const confirm = useConfirmDialog();

  const [uploadStage, setUploadStage] = useState<UploadStage>(UploadStage.UPLOAD);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [files, setFiles] = useState<Array<File>>([]);
  const [uploadedMediaItems, setUploadedMediaItems] = useState<Array<M24MediaModel>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<M24MediaModel>>([]);

  const handleUploadSelected = async () => {
    setIsLoading(true);
    const uploaded: Array<M24MediaModel> = [];
    await Promise.all(
      files.map(async (file) => {
        await Api.uploadMediaToDms(siteId, file, getMediaType(file.type))
          .fetchDirect(null)
          .then((media) => media && uploaded.push(media));
      }),
    )
      .then(() => setUploadedMediaItems(uploaded))
      .then(() => setIsLoading(false));
    setUploadStage(UploadStage.EDIT);
  };

  const onSelectUploaded = (item: M24MediaModel) => {
    setSelectedItems((selected) => toggleItem(selected, item, (a, b) => a.id === b.id));
  };

  const resetStage = () => {
    setUploadStage(UploadStage.UPLOAD);
    setFiles([]);
    setUploadedMediaItems([]);
    setSelectedItems([]);
  };

  const handleCloseButton = async () => {
    if (uploadStage === UploadStage.UPLOAD || (await confirm(tComp('MediaView.MediaUpload.ConfirmLeaveEditStage')))) {
      closeModal();
      resetStage();
    }
  };

  const incompleteIcon = (name: string, active: boolean) => (
    <span
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '1.5rem',
        height: '1.5rem',
        fontSize: 'small',
        borderRadius: '50%',
        backgroundColor: active ? Styles.Colors.SUCCESS : Styles.Colors.MEDIUM_DARK_GREY,
        color: 'white',
        marginRight: '0.5rem',
      }}>
      {name}
    </span>
  );

  const getStepIcon = (name: string, active: boolean, completed: boolean) => {
    if (active) {
      return incompleteIcon(name, true);
    }
    if (completed) {
      return <MaterialSymbol name='check_circle' fill color='success' />;
    }
    return incompleteIcon(name, false);
  };

  const renderStageStepper = () => {
    return (
      <Stepper activeStep={uploadStage === UploadStage.UPLOAD ? 0 : 1} sx={{ margin: '0 0 2rem 0' }} alternativeLabel>
        <Step completed>
          <StepLabel StepIconComponent={(props) => getStepIcon('1', props.active, props.completed)}>
            Last opp filer
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={(props) => getStepIcon('2', props.active, props.completed)}>
            Legg til metadata
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={(props) => getStepIcon('3', props.active, props.completed)}>
            Legg til i mediegalleri
          </StepLabel>
        </Step>
      </Stepper>
    );
  };

  return (
    <Dialog open={open} fullScreen sx={{ margin: 4 }}>
      <DialogTitle> {tComp('MediaView.MediaUpload.Header')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            maxWidth: '1200px',
            mx: 'auto',
            mb: '6rem',
          }}>
          {renderStageStepper()}
        </Box>
        {isLoading && <Loader />}
        {!isLoading && uploadStage === UploadStage.UPLOAD && (
          <UploadMediaStage validFiles={files} setValidFiles={setFiles} />
        )}
        {!isLoading && uploadStage === UploadStage.EDIT && (
          <EditMediaStage
            uploadsFailed={files.length - uploadedMediaItems.length}
            uploadedMediaItems={uploadedMediaItems}
            selectedItems={selectedItems}
            handleSelectItem={onSelectUploaded}
            siteId={siteId}
            {...rest}
          />
        )}
      </DialogContent>
      <DialogActions>
        {uploadStage === UploadStage.UPLOAD && (
          <>
            <Button variant='contained' onClick={handleCloseButton}>
              {tCommon('cancel')}
            </Button>
            <Button variant='contained' color='success' disabled={!files.length} onClick={handleUploadSelected}>
              {tComp('MediaView.MediaUpload.Upload')}
            </Button>
          </>
        )}
        {uploadStage === UploadStage.EDIT && (
          <Button variant='contained' color='success' onClick={handleCloseButton}>
            {tCommon('done')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadMediaModal;
