import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { CardModuleBlock as CardModuleBlockModel } from '../../../../declarations/models/blocks/CardModuleBlock';
import { CardSize } from '../../../../declarations/models/CardSize';
import { useFormConfiguration } from '../../../../components/forms/Form';

export const CardModuleBlockSizeSelector: FC = () => {
  const { blockPath } = useCurrentBlock();
  const { t: tComponents } = useTranslation('components');
  const config = useFormConfiguration();

  const { field: cardSize } = useController<
    BlockSpecificPage<CardModuleBlockModel>,
    `${typeof blockPath}.settings.size`
  >({
    name: `${blockPath}.settings.size`,
  });

  return (
    <FormControl fullWidth color={config.color}>
      <FormLabel>{tComponents(`CardModuleBlock.CardSizeSelector.label`)}</FormLabel>
      <RadioGroup row {...cardSize} value={cardSize.value || ''} defaultValue='' onClick={(e) => e.stopPropagation()}>
        <FormControlLabel
          control={<Radio size='small' />}
          label={tComponents(`CardModuleBlock.CardSizeSelector.undefined`)}
          value=''
        />
        {[CardSize.SMALL, CardSize.MEDIUM].map((sizeOption) => (
          <FormControlLabel
            key={sizeOption}
            control={<Radio size='small' />}
            label={tComponents(`CardModuleBlock.CardSizeSelector.${sizeOption}`)}
            value={sizeOption}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CardModuleBlockSizeSelector;
